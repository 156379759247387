import { render, staticRenderFns } from "./media-category-view.vue?vue&type=template&id=81bb35d4&lang=pug&"
import script from "./media-category-view.vue?vue&type=script&lang=ts&"
export * from "./media-category-view.vue?vue&type=script&lang=ts&"
import style0 from "./media-category-view.vue?vue&type=style&index=0&id=81bb35d4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports