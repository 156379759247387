
import Vue from 'vue';
import dialogUpdateAttendeeUser from '@/components/user/dialog-update-attendee-user.vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {formatDate} from '@/filters/format.date.filter';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      showDialog: this.show,
      editedAttendeeUser: {...this.value},
      showUpdateAttendeeDialog: false,
      deviceIOSListHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Model',
          value: 'model',
        },
        {
          text: 'Language',
          value: 'language',
        },
        {
          text: 'Created at',
          value: 'createdAt',
        },
      ],
      estimationHeaders: [
        {
          text: 'Total screen (mins)',
          value: 'totalScreenTimeMinutes',
        },
        {
          text: 'Daily TV (mins)',
          value: 'dailyTVMinutes',
        },
        {
          text: 'Daily video games (mins)',
          value: 'dailyVideoGameMinutes',
        },
        {
          text: 'Daily smartphone (mins)',
          value: 'dailyOwnSmartPhoneMinutes',
        },
        {
          text: 'Daily tablet (mins)',
          value: 'dailyOwnTabletMinutes',
        },
        {
          text: 'Daily educative (mins)',
          value: 'dailyEducativeTimeMinutes',
        },
        {
          text: 'Diagnosed screen time (mins)',
          value: 'diagnosedScreenTimeMinutes',
        },
      ],
      stepListHeaders: [
        {
          text: 'Time reduction (%)',
          value: 'totalTimeReductionPercent',
        },
        {
          text: 'Educative time (%)',
          value: 'educativeTimePercent',
        },
        {
          text: 'Total time (mins)',
          value: 'totalTimeMinutes',
        },
        {
          text: 'Educative time (mins)',
          value: 'educativeTimeMinutes',
        },
        {
          text: 'Entertainment time (mins)',
          value: 'entertainmentTimeMinutes',
        },
        {
          text: 'SmartPhone (mins)',
          value: 'estimatedSmartPhoneMinutes',
        },
        {
          text: 'Tablet (mins)',
          value: 'estimatedTabletMinutes',
        },
        {
          text: 'Accepted',
          value: 'accepted',
        },
      ],
      screenTimeCommitmentHeaders: [
        {
          text: 'Entertainment screen time allowed (s)',
          value: 'entertainmentScreenTimeSecondAllowed',
        },
        {
          text: 'Educational screen time commitment (s)',
          value: 'educationalScreenTimeSecondCommitment',
        },
      ],
      attendeeHeadersWithActions: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Gender',
          value: 'gender',
        },
        {
          text: 'Birth date',
          value: 'birthDate',
        },
        {
          text: 'Number of devices',
          value: 'nDevice',
        },
        {
          text: 'Rest period',
          value: 'restPeriod',
        },
        {
          text: 'Preset',
          value: 'preset',
        },
        {
          text: 'Mediation state',
          value: 'mediationState',
        },
        {
          text: 'Number of snapshots',
          value: 'nSnapshots',
        },
        {
          text: 'Created at',
          value: 'createdAt',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
    };
  },
  filters: {
    formatDate,
    formatDateTime,
  },
  computed: {
    estimationItem() {
      const stepList = this.value.mediation.stepListJsonb;
      return [{
        totalScreenTimeMinutes: stepList.estimation.totalScreenTimeMinutes,
        dailyTVMinutes: stepList.estimation.dailyTVMinutes,
        dailyVideoGameMinutes: stepList.estimation.dailyVideoGameMinutes,
        dailyOwnSmartPhoneMinutes: stepList.estimation.dailyOwnSmartPhoneMinutes,
        dailyOwnTabletMinutes: stepList.estimation.dailyOwnTabletMinutes,
        dailyEducativeTimeMinutes: stepList.estimation.dailyEducativeTimeMinutes,
        diagnosedScreenTimeMinutes: stepList.estimation.diagnosedScreenTimeMinutes,
      }];
    },
  },
  components: {
    dialogUpdateAttendeeUser,
  },
  methods: {
    openAttendeeDialog() {
      this.$emit('openAttendeeDialog');
    },
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
  },
});
