import {ApiService} from '@/service/api/api.service';
import {
  ICreateRecommendedAppDto,
} from '@/service/api/recommendedApp/dto/create.recommended.app.dto';
import {IRecommendedApp} from '@/interface/recommended.app.interface';
import {
  IRecommendedAppResponseDto,
} from '@/service/api/recommendedApp/dto/recommended.app.response.dto';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {EActiveState} from '@/enum/active.enum';
import {EDevicePlatform} from '@/enum/device.enum';

const GET_RECOMMENDED_APP_LIST_URI = (devicePlatform: EDevicePlatform) => {
  const baseUri: string = '/api/admin/recommended-app/';
  if (devicePlatform === EDevicePlatform.ANDROID) {
    return baseUri + 'android';
  } else {
    return baseUri + 'ios';
  }
}
const UPDATE_ORDERED_RECOMMENDED_APP_LIST = (devicePlatform: EDevicePlatform) => {
  const baseUri: string = '/api/admin/ordered-recommended-app/';
  if (devicePlatform === EDevicePlatform.ANDROID) {
    return baseUri + 'android';
  } else {
    return baseUri + 'ios';
  }
};

const NEW_RECOMMENDED_APP_URI = '/api/recommended-mobile-application/new';
const UPDATE_RECOMMENDED_APP_URI = (
  recommendedAppUUID: string,
) => `/api/recommended-mobile-application/${recommendedAppUUID}`;
const DELETE_RECOMMENDED_APP_URI = (
  recommendedAppUUID: string,
) => `/api/recommended-mobile-application/${recommendedAppUUID}`;

export class RecommendedAppApiService {
  public static async getRecommendedAppListFromApi(
    args: IGenericListQuery,
    devicePlatform: EDevicePlatform,
  ): Promise<IRecommendedAppResponseDto> {
    return await ApiService.get(GET_RECOMMENDED_APP_LIST_URI(devicePlatform), args);
  }

  public static async getFullActiveRecommendedAppList(
    devicePlatform: EDevicePlatform,
  ): Promise<IRecommendedApp[]> {
    return (await ApiService.get(
      GET_RECOMMENDED_APP_LIST_URI(devicePlatform),
      {
        pageNumber: 0,
        pageSize: -1,
        active: EActiveState.ACTIVE,
      },
    )).recommendedAppList;
  }

  public static async updateFullActiveRecommendedAppList(
    orderedRecommendedAppList: IRecommendedApp[],
    devicePlatform: EDevicePlatform,
  ): Promise<void> {
    await ApiService.post(UPDATE_ORDERED_RECOMMENDED_APP_LIST(devicePlatform), orderedRecommendedAppList);
  }

  public static async newRecommendedApp(
    recommendedApp: ICreateRecommendedAppDto,
    devicePlatform: EDevicePlatform,
  ): Promise<IRecommendedApp> {
    const dataToSend = {
      ...recommendedApp,
      devicePlatform: devicePlatform,
    };
    return await ApiService.post(NEW_RECOMMENDED_APP_URI, dataToSend);
  }

  public static async updateRecommendedApp(
    recommendedAppUUID: string,
    recommendedApp: Partial<IRecommendedApp>,
  ): Promise<IRecommendedApp> {
    return await ApiService.post(
      UPDATE_RECOMMENDED_APP_URI(recommendedAppUUID),
      recommendedApp,
    );
  }

  public static async deleteRecommendedApp(recommendedAppUUID: string): Promise<void> {
    await ApiService.delete(DELETE_RECOMMENDED_APP_URI(recommendedAppUUID));
  }
}
