
import Vue from 'vue';
import {AttendeeUserApiService} from '@/service/api/user/attendeeUser/attendee.user.api.service';
import {
  GetAdminAttendeeCsvResponseDto, GetAdminAttendeeScreenTimeConsumptionCsvResponseDto,
  GetAdminOldMediationCsvResponseDto,
} from '@/service/api/user/attendeeUser/dto/attendee.user.csv.dto';
import {stringFromDate} from '@/service/static/date.service';

export default Vue.extend({
  data: () => ({
    synchronized: false,
    attendeeUserCSVAttributes: 'attendee_id,birthdate,createdAt,'
      + 'entertainmentSecond,educationalSecond,totalSecond,restPeriod,'
      + 'restPeriodBeginHour,restPeriodEndHour,restPeriodBeginMinute,restPeriodEndMinute,'
      + 'contentFilteringPreset,educationalUnlimited',
    oldMediationCSVAttributes: 'attendeeId,intercomEmail,'
      + 'accepted,exchangeCount,createdAt,firstExchangeAt,lastExchangeAt,'
      + 'diagnosedScreenTimeMinutes,lastExchangeTotalTimeMinutes,lastExchangeEducationalTimeMinutes,'
      + 'lastExchangeTotalTimeReductionPercent',
    screenTimeConsumptionEvolutionCSVAttributes: 'attendeeId,intercomEmail,'
      + 'birthDate,createdAt,active,snapshotCount,'
      + 'ms1EntertainmentPercent,ms2EntertainmentPercent,ms3EntertainmentPercent,ms4EntertainmentPercent,'
      + 'ms1EducationalPercent,ms2EducationalPercent,ms3EntertainmentPercent,ms4EducationalPercent,'
      + 'ms1Date,ms2Date,ms3Date,ms4Date',
  }),
  methods: {
    async getAttendeeCSV() {
      this.synchronized = true;
      const report: GetAdminAttendeeCsvResponseDto[] = await AttendeeUserApiService.getAttendeeUserCSV();
      const name = 'attendee-user-csv-' + stringFromDate(new Date());
      let csv = this.attendeeUserCSVAttributes + '\n';
      for (let i = 0, len = report?.length ?? 0; i < len; i += 1) {
        const raw = report[i];
        const line = `"${raw.id}","${raw.birthDate}","${raw.createdAt}",`
          + `"${raw.entertainmentSecondCommitment}",`
          + `"${raw.educationalSecondCommitment}","${raw.totalSecond}","${raw.restPeriod}",`
          + `"${raw.restPeriodBeginHour}","${raw.restPeriodEndHour}","${raw.restPeriodBeginMinute}",`
          + `"${raw.restPeriodEndMinute}","${raw.contentFilteringPreset}","${raw.educationalUnlimited}"\n`;
        csv += line;
      }
      this.downloadCsvWithName({csv, name});
    },
    async getOldMediationCSV() {
      this.synchronized = true;
      const report: GetAdminOldMediationCsvResponseDto[] = await AttendeeUserApiService
        .getOldMediationCSV();
      const name = 'old-mediation-csv-' + stringFromDate(new Date());
      let csv = this.oldMediationCSVAttributes + '\n';
      for (let i = 0, len = report?.length ?? 0; i < len; i += 1) {
        const raw = report[i];
        const line = `"${raw.attendeeUserId}","${raw.intercomEmail}","${raw.accepted}","${raw.exchangeCount}",`
          + `"${raw.createdAt}","${raw.firstExchangeAt}","${raw.lastExchangeAt}",`
          + `"${raw.diagnosedScreenTimeMinutes}","${raw.lastExchangeTotalTimeMinutes}",`
          + `"${raw.lastExchangeEducationalTimeMinutes}","${raw.lastExchangeTotalTimeReductionPercent}"\n`;
        csv += line;
      }
      this.downloadCsvWithName({csv, name});
    },
    async getScreenTimeConsumptionEvolutionCSV() {
      this.synchronized = true;
      const report: GetAdminAttendeeScreenTimeConsumptionCsvResponseDto[] = await AttendeeUserApiService
        .getScreenTimeConsumptionEvolutionCSV();
      const name = 'screen-time-consumption-evolution-csv-' + stringFromDate(new Date());
      let csv = this.screenTimeConsumptionEvolutionCSVAttributes + '\n';
      for (let i = 0, len = report?.length ?? 0; i < len; i += 1) {
        const raw = report[i];
        const line = `"${raw.attendeeUserId}","${raw.intercomEmail}","${raw.birthDate}","${raw.createdAt}",`
          + `"${raw.active}","${raw.snapshotCount}","${raw.ms1EntertainmentPercent}",`
          + `"${raw.ms2EntertainmentPercent}","${raw.ms3EntertainmentPercent}",`
          + `"${raw.ms4EntertainmentPercent}","${raw.ms1EducationalPercent}","${raw.ms2EducationalPercent}",`
          + `"${raw.ms3EducationalPercent}","${raw.ms4EducationalPercent}","${raw.ms1Date}",`
          + `"${raw.ms2Date}","${raw.ms3Date}","${raw.ms4Date}"\n`;
        csv += line;
      }
      this.downloadCsvWithName({csv, name});
    },
    downloadCsvWithName(args: {csv: string, name: string}) {
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(args.csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = `${args.name}.csv`;
      hiddenElement.click();
      this.closeSynchronizedAlert();
    },
    closeSynchronizedAlert() {
      this.synchronized = false;
    },
  },
});
