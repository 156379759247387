export const mediaHeaderList = [
  {
    text: 'Title',
    value: 'title',
    sortable: false,
  },
  {
    text: 'Video ID',
    value: 'videoId',
    sortable: false,
  },
  {
    text: 'Country list',
    value: 'countryList',
    sortable: false,
  },
  {
    text: 'Categories',
    value: 'mediaCategoryEnNameList',
    sortable: false,
  },
  {
    text: 'Duration',
    value: 'duration',
    sortable: false,
  },
  {
    text: 'Created at',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Active',
    value: 'active',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
