<template lang='pug'>
div(class='test')
  ChartLine(
    id="snapshot-chart-id"
    :data="chartData"
    :options="chartOptions"
  )

  v-dialog(v-model='showPopup' max-width='800px')
    v-card
      v-card-title {{this.pointType}} snapshots details for date {{ this.popupDate }}
      v-card-text
        v-data-table(
          :headers='headers'
          :items='popupData'
        )
</template>

<script>
import {
  CategoryScale,
  Chart,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {Line as ChartLine} from 'vue-chartjs';

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);
export default {
  name: 'screenshotsChart',
  components: { ChartLine },
  props: {
    labels: Array,
    curve1Values: Array,
    curve2Values: Array,
    curve1Details: Array,
    curve2Details: Array,
  },
  data() {
    return {
      showPopup: false,
      popupDate: '',
      popupData: [],
      pointType: '',
      headers: [
        {
          text: 'Intercom email',
          value: 'intercomEmail',
        },
        {
          text: 'Neutral (seconds)',
          value: 'totalScreenTime',
        },
        {
          text: 'Entertainment (seconds)',
          value: 'entertainmentScreenTime',
        },
        {
          text: 'Educational (seconds)',
          value: 'educationalScreenTime',
        },
      ],
      chartData: {
        labels: this.labels,
        datasets: [
          {
            borderColor: '#FF657C',
            backgroundColor: '#FF657C',
            data: this.curve1Values,
            lineTension: 0.3,
            label: 'Snapshots with attendee per day',
          },
          {
            borderColor: 'white',
            backgroundColor: 'white',
            data: this.curve2Values,
            lineTension: 0.3,
            label: 'Lonely snapshots per day',
          },
        ],
      },
    };
  },
  computed: {
    chartOptions() {
      return {
        onClick: (e) => this.onClickOnPoint(e),
        onHover: (e) => {
          const myChart = Chart.getChart('snapshot-chart-id');
          const hoveredPoint = myChart.getElementsAtEventForMode(
            e,
            'point',
            { intersect: true},
            false,
          );
          if (hoveredPoint.length) {
            e.native.target.style.cursor = 'pointer';
          } else {
            e.native.target.style.cursor = 'default';
          }
        },
        responsive: true,
        scales: {
          x: {
            ticks: {
              font: {
                size: 20,
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0,
              font: {
                size: 20,
              },
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              font: {
                size: 20,
              },
            },
          },
        },
      };
    },
  },
  methods: {
    onClickOnPoint(e) {
      const myChart = Chart.getChart('snapshot-chart-id');
      const clickedPoint = myChart.getElementsAtEventForMode(
        e,
        'point',
        { intersect: true},
        false,
      );

      let datasetIndex;
      let curve;
      let snapshotPoint = clickedPoint.find(
        (point) => point.datasetIndex === 0,
      );

      if (snapshotPoint) {
        datasetIndex = 0;
        curve = this.curve1Details;
        this.pointType = 'With attendee';
      } else {
        datasetIndex = 1;
        snapshotPoint = clickedPoint.find(
          (point) => point.datasetIndex === datasetIndex,
        );
        curve = this.curve2Details;
        this.pointType = 'With attendee';
      }

      this.showPopup = true;
      const pointIndex = snapshotPoint.index;
      this.popupDate = this.labels[pointIndex];
      this.popupData = curve[pointIndex];
      this.popupData.forEach((data) => {
        if (!data.intercomEmail) {
          // eslint-disable-next-line
          data.intercomEmail = 'unknown';
        }
      });
    },
  },
};
</script>
