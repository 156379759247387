export const observerUserHeaderList = [
  {
    text: 'Email',
    value: 'contact.email',
    sortable: false,
  },
  {
    text: 'Mail notifications ?',
    value: 'contact.mailNotificationAllowed',
    sortable: false,
  },
  {
    text: 'Created at',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
