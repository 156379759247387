import {ECountry} from '@/enum/country.enum';
import {EMediaCategory} from '@/enum/media.enum';
import {EYoutubeMediaStoreAttribute, youtubeMediaStore} from '@/store/youtube.media.store';
import {IYoutubeMedia} from '@/interface/youtube.media.interface';

export const mediaRuleListJson = {
  titleRules: [
    (v: string) => Boolean(v) || 'Title is required',
    (v: string) => (v && v.length < 256) || 'Title must be less than 256 characters',
  ],
  descriptionRules: [
    (v: string) => Boolean(v) || 'Description is required',
    (v: string) => (v && v.length < 1024) || 'Description must be less than 1024 characters',
  ],
  countryRules: [
    (v: ECountry[]) => v.length > 0 || 'Country is required',
  ],
  categoryRules: [
    (v: EMediaCategory[]) => v.length > 0 || 'Media category is required',
  ],
  durationHourRules: [
    (v: string) => parseInt(v, 10) === 0 || Boolean(v) || 'Duration hour is required',
    (v: string) => /^\d*$/.test(v) || 'Duration hour must be a positive integer',
  ],
  durationMinuteRules: [
    (v: string) => parseInt(v, 10) === 0 || Boolean(v) || 'Duration minute is required',
    (v: string) => /^\d*$/.test(v) || 'Duration minute must be a positive integer',
  ],
  durationSecondRules: [
    (v: string) => parseInt(v, 10) === 0 || Boolean(v) || 'Duration second is required',
    (v: string) => /^\d*$/.test(v) || 'Duration second must be a positive integer',
  ],
  videoIdRules: (editedItemUUID: string) => [
    (v: string) => Boolean(v) || 'Video ID is required',
    (v: string) => (v && v.length <= 256) || 'Video ID must be less than 256 characters',
    (videoId: string) => {
      const nVideoWithSameId = youtubeMediaStore
        .state[EYoutubeMediaStoreAttribute.YOUTUBE_MEDIA_LIST].filter(
          (media: IYoutubeMedia) => media.videoId === videoId && editedItemUUID !== media.uuid,
        ).length;
      return nVideoWithSameId === 0 || 'This video Id already exists';
    },
  ],
};
