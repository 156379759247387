
import Vue from 'vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {ESubscriptionStoreAttribute, subscriptionStore} from '@/store/subscription.store';
import {ISubscription} from '@/interface/subscription.interface';
import DialogDelete from '@/components/dialog-delete.vue';
import {currentUserStore} from '@/store/user/current.user.store';
import {EUserRole} from '@/enum/user.enum';
import DialogUpdateTrial from '@/components/subscription/dialog-update-trial.vue';
import {IAttendeeUser} from '@/interface/attendee.user.interface';
import {footerProps} from '@/views/table.const';
import {ESubscriptionTrialValidity} from '@/enum/subscription.enum';
import DialogUpdateAttendeeUser from '@/components/user/dialog-update-attendee-user.vue';
import {
  attendeeHeaders,
  headers,
  observerHeaders,
  subscriptionTrialHeaders,
} from '@/views/subscription/subscription.headers.json';
import DialogAttendeeUserDetails from '@/components/user/dialog-attendee-user-details.vue';
import DialogObserverUserDetails from '@/components/user/dialog-observer-user-details.vue';
import {IObserverUser} from '@/interface/observer.user.interface';
import {formatDate} from '@/filters/format.date.filter';
import {ApiService} from '@/service/api/api.service';
import {SubscriptionApiService} from '@/service/api/subscription/subscription.api.service';

export default Vue.extend({
  data: () => ({
    footerProps,
    isLoading: false,
    options: {} as IPaginationOptions,
    editedItem: {} as Partial<ISubscription>,
    expanded: [],
    search: '',
    searchRules: [
      (v: any) => {
        return (v === '' || v.length >= 5) || "This field must have at least 5 characters";
      },
    ],
    creatingTrial: false,
    withEmailOnly: false,
    withAttendeeDeviceOnly: false,
    subscriptionValidity: Object.values(ESubscriptionTrialValidity),
    withSubscriptionValidityList: [],
    showDeleteDialog: false,
    showUpdateTrialDialog: false,
    userIsSuperAdmin: false,
    showAttendeePopup: false,
    showObserverPopup: false,
    showUpdateAttendeeDialog: false,
    clickedAttendeeUser: {} as IAttendeeUser,
    editedAttendeeUser: {} as IAttendeeUser,
    clickedObserverUser: {} as IObserverUser,
    subscriptionTrialHeaders,
    observerHeaders,
    attendeeHeaders,
    headers,
  }),
  components: {
    DialogDelete,
    DialogUpdateTrial,
    DialogUpdateAttendeeUser,
    DialogAttendeeUserDetails,
    DialogObserverUserDetails,
  },
  mounted() {
    this.userIsSuperAdmin = currentUserStore.state.user.role
      === EUserRole.INTERNAL_USER_SUPER_ADMIN;
  },
  computed: {
    totalSubscription(): number {
      return subscriptionStore.state.totalSubscription;
    },
    subscriptionList(): ISubscription[] {
      const subscriptionList: ISubscription[] = subscriptionStore
        .state[ESubscriptionStoreAttribute.SUBSCRIPTION_LIST];
      return subscriptionList;
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  filters: {
    formatDate,
    formatDateTime,
  },
  methods: {
    setEditedItem(item: ISubscription) {
      this.editedItem = {...item};
    },
    openDeleteDialog(item: ISubscription) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(
        'deleteSubscription',
        {subscriptionUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },
    openUpdateTrialDialog(item: ISubscription) {
      this.setEditedItem(item);
      this.showUpdateTrialDialog = true;
    },
    closeUpdateTrialDialog() {
      this.showUpdateTrialDialog = false;
    },
    openAttendeePopup(value: any) {
      this.clickedAttendeeUser = value;
      this.showAttendeePopup = true;
    },
    closeAttendeePopup() {
      this.showAttendeePopup = false;
    },
    openObserverPopup(value: any) {
      this.clickedObserverUser = value;
      this.showObserverPopup = true;
    },
    closeObserverPopup() {
      this.showObserverPopup = false;
    },
    openAttendeeDialog() {
      this.editedAttendeeUser = {...this.clickedAttendeeUser};
      this.editedAttendeeUser.birthDate = new Date(this.editedAttendeeUser.birthDate)
        .toISOString().slice(0, 10);
      this.showUpdateAttendeeDialog = true;
    },
    closeUpdateAttendeeDialog() {
      this.showUpdateAttendeeDialog = false;
    },
    async saveAttendee() {
      await this.$store.dispatch(
        'updateAttendeeUser',
        {
          attendeeUserUUID: this.editedAttendeeUser.uuid,
          args: {
            attendeeName: this.editedAttendeeUser.name,
            gender: this.editedAttendeeUser.gender,
            birthDate: this.editedAttendeeUser.birthDate,
          },
        },
      );
      await this.$store.dispatch(
        'updateAttendeeUserPreset',
        {
          attendeeUserUUID: this.editedAttendeeUser.uuid,
          args: {
            preset: this.editedAttendeeUser.preset,
          },
        },
      );
      await this.$store.dispatch(
        'updateAttendeeUserRestPeriod',
        {
          attendeeUserUUID: this.editedAttendeeUser.uuid,
          args: {
            restPeriod: this.editedAttendeeUser.restPeriod,
          },
        },
      );
      this.clickedAttendeeUser = {...this.editedAttendeeUser};
      this.closeUpdateAttendeeDialog();
      await this.getDataFromApi();
    },
    async createSubscriptionTrial(uuid: string) {
      this.isLoading = true;
      this.creatingTrial = true;
      try {
        await SubscriptionApiService.createSubscriptionTrial(uuid);
        await this.getDataFromApi(true);
      } finally {
        this.creatingTrial = false;
        this.isLoading = false;
      }
    },
    async getDataFromApi(isSearchOperation: boolean = false) {
      // console.log('isSearchOperation', isSearchOperation);
      if (isSearchOperation && !this.$refs.inputSearch.validate()) {
        // console.log(this.$refs.inputSearch.validate())
        return;
      }
      this.isLoading = true;
      await this.$store.dispatch(
        'getSubscriptionList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
          withEmailOnly: this.withEmailOnly,
          withAttendeeDeviceOnly: this.withAttendeeDeviceOnly,
          withSubscriptionValidityList: this.withSubscriptionValidityList,
        },
      );
      this.isLoading = false;
    },
  },
});
