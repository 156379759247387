import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {IYoutubeMediaListResponseDto} from '@/service/api/device/dto/device.list.response.dto';
import {ApiService} from '@/service/api/api.service';

const GET_DEVICE_LIST = '/api/admin/device';

export class DeviceApiService {
  public static async getDeviceList(
    args: IGenericListQuery,
  ): Promise<IYoutubeMediaListResponseDto> {
    return await ApiService.get(GET_DEVICE_LIST, args);
  }
}
