
import Vue from 'vue';
import {
  ITransferableInternalUser,
} from '@mr-arthur/ma-api/package-registry/dist/src/Service/Global/User/user.global.interface';
import {ECurrentUserStoreAttribute} from '@/store/user/current.user.store';
import {isDef} from '@/service/static/control.service';
import {initialFromFirstnameLastname} from '@/service/static/string.manipulation.service';

export default Vue.extend({
  data: () => ({}),
  methods: {
    disconnect() {
      this.$router.push('/login');
    },
  },
  components: {},
  computed: {
    userInfo() {
      const user: ITransferableInternalUser = this.$store.getters[ECurrentUserStoreAttribute.USER];
      return {
        initials: isDef(user) ? initialFromFirstnameLastname({
          firstName: user.firstName,
          lastName: user.lastName,
        }) : '^^',
        fullName: isDef(user) ? `${user.firstName} ${user.lastName}` : '^^ ^^',
        email: isDef(user) ? user.contact.email : '^^@^^.^^',
      };
    },
  },
});
