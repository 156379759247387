
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: String,
    nAttendee: Number,
    nSubscription: Number,
  },
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  directives: {
  },
});
