export const recommendedIosAppHeaderList = [
  {
    text: 'Name',
    value: 'name',
    sortable: false,
  },
  {
    text: 'Device type list',
    value: 'deviceTypeList',
    sortable: false,
  },
  {
    text: 'Country list',
    value: 'countryList',
    sortable: false,
  },
  {
    text: 'Categories',
    value: 'appCategoryEnNameList',
    sortable: false,
  },
  {
    text: 'Link',
    value: 'link',
    sortable: false,
  },
  {
    text: 'urlScheme',
    value: 'urlScheme',
    sortable: false,
  },
  {
    text: 'Created at',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Active',
    value: 'active',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
