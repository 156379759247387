
import Vue from 'vue';
import {VForm} from '@/type/vform.type';
import {EUserGender} from '@/enum/user.enum';
import {EParentalControlSettingsPreset} from '@/enum/parental.control.settings.preset.enum';
import {ERestPeriod} from '@/enum/rest.period.enum';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      valid: true,
      userGender: Object.values(EUserGender),
      preset: Object.values(EParentalControlSettingsPreset),
      restPeriod: Object.values(ERestPeriod),
      showDialog: this.show,
    };
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
    save() {
      this.showDialog = false;
      if (this.form.validate()) {
        this.$emit('save');
      }
    },
  },
});
