
import Vue from 'vue';
import {EYoutubeMediaStoreAttribute, youtubeMediaStore} from '@/store/youtube.media.store';
import {IYoutubeMedia} from '@/interface/youtube.media.interface';
import {EActiveState} from '@/enum/active.enum';
import {formatDateTime} from '@/filters/format.date.time.filter';
import DialogCreateUpdate from '@/components/media/dialog-create-update.vue';
import DialogDelete from '@/components/dialog-delete.vue';
import ActiveFilter from '@/components/active-filter.vue';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {IMediaCategory} from '@/interface/category.interface';
import DialogOrderMedia from '@/components/media/dialog-order-media.vue';
import {footerProps} from '@/views/table.const';
import {mediaHeaderList} from './media.header.list';

export default Vue.extend({
  data: () => ({
    footerProps,
    isLoading: false,
    options: {} as IPaginationOptions,
    expanded: [],
    activeFilterList: [EActiveState.ACTIVE],
    search: '',
    showOrderDialog: false,
    showDeleteDialog: false,
    showCreateUpdateDialog: false,
    editedIndex: -1,
    editedItem: {
      active: EActiveState.ACTIVE,
    } as Partial<IYoutubeMedia>,
    defaultItem: {
      title: '',
      description: '',
      videoId: '',
      countryList: [],
      categoryList: [],
      durationHour: '',
      durationMinute: '',
      durationSecond: '',
      active: EActiveState.ACTIVE,
    },
    headers: mediaHeaderList,
  }),
  components: {
    ActiveFilter,
    DialogDelete,
    DialogCreateUpdate,
    DialogOrderMedia,
  },
  computed: {
    totalMedia() {
      return youtubeMediaStore.state.totalYoutubeMedia;
    },
    mediaList() {
      const mediaList = youtubeMediaStore.state.youtubeMediaList as IYoutubeMedia[];
      const formattedMediaList: any = [];
      mediaList.forEach((media: any) => {
        const formattedMedia = JSON.parse(JSON.stringify(media));
        formattedMedia.mediaCategoryEnNameList = formattedMedia.mediaCategoryList
          .map((cat: IMediaCategory) => cat.enName);
        formattedMedia.mediaCategoryIdList = formattedMedia.mediaCategoryList
          .map((cat: IMediaCategory) => cat.id);
        formattedMediaList.push(formattedMedia);
      });
      return formattedMediaList;
    },
    fullYoutubeMediaList(): IYoutubeMedia[] {
      return youtubeMediaStore
        .state[EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New media' : 'Edit media';
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  filters: {
    formatDateTime,
  },
  methods: {
    activeState() {
      return this.activeFilterList.length === 1 ? this.activeFilterList[0] : null;
    },
    openCreateDialog() {
      this.editedItem = {...this.defaultItem};
      this.editedIndex = -1;
      this.openCreateUpdateDialog();
    },
    openUpdateDialog(item: IYoutubeMedia) {
      this.setEditedItem(item);
      this.openCreateUpdateDialog();
    },
    async openOrderDialog() {
      await this.$store.dispatch('getFullActiveYoutubeMediaListFromApi');
      this.showOrderDialog = true;
    },
    closeOrderDialog() {
      this.showOrderDialog = false;
    },
    async saveOrderDialog() {
      await this.getDataFromApi();
      this.showOrderDialog = false;
    },
    openCreateUpdateDialog() {
      this.showCreateUpdateDialog = true;
    },
    closeCreateUpdateDialog() {
      this.showCreateUpdateDialog = false;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    openDeleteDialog(item: IYoutubeMedia) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    setEditedItem(item: IYoutubeMedia) {
      this.editedItem = {...item};
      this.editedIndex = this.mediaList.indexOf(item);
    },
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch('getFullActiveYoutubeMediaListFromApi');
      await this.$store.dispatch(
        'getMediaList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
          active: this.activeState(),
        },
      );
      this.isLoading = false;
    },

    async deleteItemConfirm() {
      await this.$store.dispatch(
        'deleteMedia',
        {mediaUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          'updateMedia',
          {mediaUUID: this.editedItem.uuid, mediaData: this.editedItem},
        );
      } else {
        await this.$store.dispatch(
          'createMedia',
          {mediaData: this.editedItem},
        );
      }
      this.closeCreateUpdateDialog();
      await this.getDataFromApi();
    },
  },
});
