
import Vue from 'vue';
import {
  ITransferableInternalUser,
} from '@mr-arthur/ma-api/package-registry/dist/src/Service/Global/User/user.global.interface';
import {ECurrentUserStoreAttribute} from '@/store/user/current.user.store';
import {isDef, isUndef} from '@/service/static/control.service';
import {CurrentUserApiService} from '@/service/api/user/current.user.api.service';

export default Vue.extend({
  name: 'ValidateCodeView',
  components: {
  },
  data: () => ({
    valid: false,
    code: '',
    codeRules: [
      (v: string) => !!v || 'Code is required',
    ],
  }),
  methods: {
    async submit() {
      const loginEmail: string = this.$store.getters[ECurrentUserStoreAttribute.LOGIN_EMAIL];
      if (isUndef(loginEmail)) {
        this.$router.push('/login');
      } else {
        const user: ITransferableInternalUser = await CurrentUserApiService.validateCode({
          email: loginEmail,
          code: this.code.trim(),
        });
        if (isDef(user)) {
          this.$store.commit(ECurrentUserStoreAttribute.USER, user);
          this.$router.push('/');
        }
      }
    },
  },
});
