
import Vue from 'vue'; import {mediaRuleListJson} from '@/components/media/media.rule.list.json';
import {countryList } from '@/enum/country.enum';
import {activeStateList} from '@/enum/active.enum';
import {VForm} from '@/type/vform.type';
import {IMediaCategory} from '@/interface/category.interface';
import {EMediaCategoryStoreAttribute, mediaCategoryStore} from '@/store/media.category.store';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      activeStateList,
      countryList,
      mediaRuleListJson,
      valid: true,
    };
  },
  async mounted() {
    await this.$store.dispatch('getMediaCategoryList');
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    mediaCategoryList(): IMediaCategory[] {
      const mediaCategoryList: IMediaCategory[] = mediaCategoryStore
        .state[EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST];
      return mediaCategoryList;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },

    save() {
      if (this.form.validate()) {
        this.$emit('save');
      }
    },
  },
});
