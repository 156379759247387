import {IObserverUserUpdateDto} from '@/service/api/user/observerUser/dto/observer.user.update.dto';
import {IContext} from '@/store/interface/context.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {IInternalUser} from '@/interface/internal.user.interface';
import {
  IInternalUserListResponseDto,
} from '@/service/api/user/internalUser/dto/internal.user.list.response.dto';
import {InternalUserApiService} from '@/service/api/user/internalUser/internal.user.api.service';
import {ICreateInternalUserDto} from '@/service/api/user/internalUser/dto/create.internal.user.dto';

export enum EInternalUserStoreAttribute {
  INTERNAL_USER_LIST = 'internalUserList',
  PAGE_COUNT = 'pageCount',
  TOTAL_INTERNAL_USER = 'totalInternalUser',
}

export interface IInternalUserStoreState {
  [EInternalUserStoreAttribute.INTERNAL_USER_LIST]: IInternalUser[];
  [EInternalUserStoreAttribute.PAGE_COUNT]: number;
  [EInternalUserStoreAttribute.TOTAL_INTERNAL_USER]: number;
}

export const internalUserStore = {
  state: {
    [EInternalUserStoreAttribute.INTERNAL_USER_LIST]: [],
    [EInternalUserStoreAttribute.PAGE_COUNT]: 0,
    [EInternalUserStoreAttribute.TOTAL_INTERNAL_USER]: 0,
  },
  getters: {},
  mutations: {
    [EInternalUserStoreAttribute.INTERNAL_USER_LIST]: (
      state: IInternalUserStoreState,
      internalUserList: IInternalUser[],
    ) => {
      state[EInternalUserStoreAttribute.INTERNAL_USER_LIST] = internalUserList;
    },
    [EInternalUserStoreAttribute.PAGE_COUNT]: (
      state: IInternalUserStoreState,
      pageCount: number,
    ) => {
      state[EInternalUserStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EInternalUserStoreAttribute.TOTAL_INTERNAL_USER]: (
      state: IInternalUserStoreState,
      totalInternalUser: number,
    ) => {
      state[EInternalUserStoreAttribute.TOTAL_INTERNAL_USER] = totalInternalUser;
    },
  },
  actions: {
    async getInternalUserList(context: IContext, args: IGenericListQuery): Promise<void> {
      const apiResponse: IInternalUserListResponseDto = await InternalUserApiService
        .getInternalUserList(args);
      context.commit(EInternalUserStoreAttribute.INTERNAL_USER_LIST, apiResponse.internalUserList);
      context.commit(EInternalUserStoreAttribute.PAGE_COUNT, apiResponse.pageCount);
      context.commit(EInternalUserStoreAttribute.TOTAL_INTERNAL_USER, apiResponse.totalCount);
    },

    async createInternalUser(
      context: IContext,
      data: {internalUserData: ICreateInternalUserDto},
    ): Promise<void> {
      await InternalUserApiService.newInternalUser(data.internalUserData);
    },

    async updateInternalUser(
      context: IContext,
      data: {
        internalUserUUID: string,
        internalUserData: IObserverUserUpdateDto,
      },
    ): Promise<IInternalUser> {
      return await InternalUserApiService.updateInternalUser(
        data.internalUserUUID,
        data.internalUserData,
      );
    },

    async deleteInternalUser(
      context: IContext,
      data: {internalUserUUID: string},
    ): Promise<void> {
      return await InternalUserApiService.deleteInternalUser(data.internalUserUUID);
    },
  },
};
