import {
  ITransferableInternalUser,
} from '@mr-arthur/ma-api/package-registry/dist/src/Service/Global/User/user.global.interface';
import {isDef} from '@/service/static/control.service';
import {CurrentUserApiService} from '@/service/api/user/current.user.api.service';

export enum ECurrentUserStoreAttribute {
  USER = 'user',
  LOGIN_EMAIL = 'loginEmail',
}

export interface ICurrentUserStoreState {
  [ECurrentUserStoreAttribute.USER]: {name: string},
  [ECurrentUserStoreAttribute.LOGIN_EMAIL]: string,
}

export const currentUserStore = {
  state: {
    [ECurrentUserStoreAttribute.USER]: null,
    [ECurrentUserStoreAttribute.LOGIN_EMAIL]: null,
  },
  getters: {
    [ECurrentUserStoreAttribute.USER]: (state: ICurrentUserStoreState) => state.user,
    [ECurrentUserStoreAttribute.LOGIN_EMAIL]: (state: ICurrentUserStoreState) => state.loginEmail,
  },
  mutations: {
    [ECurrentUserStoreAttribute.USER]: (state: ICurrentUserStoreState, user: {name: string}) => {
      state.user = user;
    },
    [ECurrentUserStoreAttribute.LOGIN_EMAIL]: (
      state: ICurrentUserStoreState,
      loginEmail: string,
    ) => {
      state.loginEmail = loginEmail;
    },
  },
  actions: {
    async requestValidateCode(context: any, data: {email: string;}): Promise<any> {
      const dataResponseDto = await CurrentUserApiService.requestValidationCode(data.email);
      context.commit('user', dataResponseDto.user);
      context.commit('userMe', dataResponseDto.userMe);
      return isDef(dataResponseDto) ? dataResponseDto : null;
    },
    async validateCode(context: any, data: {email: string; code: string}): Promise<void> {
      const user: ITransferableInternalUser = await CurrentUserApiService.validateCode({
        email: data.email,
        code: data.code,
      });
      context.commit('user', user);
    },
    async getMe(context: any): Promise<void> {
      const userMe: ITransferableInternalUser = await CurrentUserApiService.getMe();
      context.commit('user', userMe);
    },
    async logout(context: any): Promise<void> {
      context.commit('user', null);
      await CurrentUserApiService.logout();
    },
  },
};
