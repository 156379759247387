
import Vue from 'vue';
import {IMediaCategory} from '@/interface/category.interface';
import {EMediaCategoryStoreAttribute, mediaCategoryStore} from '@/store/media.category.store';
import DialogCreateUpdate from '@/components/media-category/dialog-create-update.vue';
import DialogDelete from '@/components/media-category/dialog-delete.vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {footerProps} from '@/views/table.const';

export default Vue.extend({
  data: () => ({
    footerProps,
    search: '',
    isLoading: false,
    showDeleteDialog: false,
    showCreateUpdateDialog: false,
    editedIndex: -1,
    editedItem: {} as Partial<IMediaCategory>,
    defaultItem: {
      frName: '',
      enName: '',
    },
    headers: [
      {
        text: 'French name',
        value: 'frName',
      },
      {
        text: 'English name',
        value: 'enName',
      },
      {
        text: 'Created at',
        value: 'createdAt',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  components: {
    DialogDelete,
    DialogCreateUpdate,
  },
  computed: {
    mediaCategoryList(): IMediaCategory[] {
      return mediaCategoryStore.state[EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New media category' : 'Edit media category';
    },
  },
  async mounted() {
    await this.getDataFromApi();
  },
  filters: {
    formatDateTime,
  },
  methods: {
    openCreateDialog() {
      this.editedItem = {...this.defaultItem};
      this.editedIndex = -1;
      this.openCreateUpdateDialog();
    },
    openUpdateDialog(item: IMediaCategory) {
      this.setEditedItem(item);
      this.openCreateUpdateDialog();
    },
    openCreateUpdateDialog() {
      this.showCreateUpdateDialog = true;
    },
    closeCreateUpdateDialog() {
      this.showCreateUpdateDialog = false;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    openDeleteDialog(item: IMediaCategory) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    setEditedItem(item: IMediaCategory) {
      this.editedItem = {...item};
      this.editedIndex = this.mediaCategoryList.indexOf(item);
    },
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch('getMediaCategoryList');
      await this.$store.dispatch('getFullActiveYoutubeMediaListFromApi');
      this.isLoading = false;
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(
        'deleteMediaCategory',
        {mediaCategoryUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          'updateMediaCategory',
          {mediaCategoryUUID: this.editedItem.uuid, dataToUpdate: this.editedItem},
        );
      } else {
        await this.$store.dispatch(
          'createMediaCategory',
          {mediaCategory: this.editedItem},
        );
      }
      this.closeCreateUpdateDialog();
      await this.getDataFromApi();
    },
  },
});
