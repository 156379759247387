
import Vue from 'vue';
import {ECurrentUserStoreAttribute} from '@/store/user/current.user.store';
import {CurrentUserApiService} from '@/service/api/user/current.user.api.service';

export default Vue.extend({
  name: 'LoginView',
  components: {
  },
  data: () => ({
    valid: false,
    email: '',
    emailRules: [
      (v: string) => !!v || 'E-mail is required',
      // (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
      (v: string) => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    submit() {
      this.$store.commit(ECurrentUserStoreAttribute.LOGIN_EMAIL, this.email);
      CurrentUserApiService.requestValidationCode(this.email);
      this.$router.push('/validate-code');
    },
  },
});
