
import Vue from 'vue';
import {AppCategoryApiService} from '@/service/api/recommendedApp/app.category.api.service';
import {IRecommendedIOSApp} from '@/interface/recommended.ios.app.interface';
import {
  ERecommendedIOSAppStoreAttribute,
  recommendedIosAppStore,
} from '@/store/recommended.ios.app.store';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      valid: true,
      appWithOnlyThisCategoryList: [] as IRecommendedIOSApp[],
      showWarning: false,
    };
  },
  async mounted() {
    await this.getFormattedApp();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteItemConfirm() {
      this.$emit('delete');
    },
    async getFormattedApp() {
      const relatedMobileApplications = await AppCategoryApiService
        .getAppListRelatedToAppCategory(this.$props.value.uuid);
      const relatedMobileApplicationsIds = relatedMobileApplications.map((app) => app.id);
      this.appWithOnlyThisCategoryList = recommendedIosAppStore
        .state[ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST]
        .filter((app: IRecommendedIOSApp) => relatedMobileApplicationsIds.includes(app.id)
          && app.subscriptionList.length === 1);
      this.showWarning = this.appWithOnlyThisCategoryList.length !== 0;
    },
  },
});
