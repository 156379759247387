export enum EUserRole {
  INTERNAL_USER_SUPER_ADMIN = 'INTERNAL_USER_SUPER_ADMIN',
  INTERNAL_USER_ADMIN = 'INTERNAL_USER_ADMIN',
  ATTENDEE_USER = 'ATTENDEE_USER',
  OBSERVER_USER = 'OBSERVER_USER',
}

export enum EUserType {
  INTERNAL_USER = 'INTERNAL_USER',
  ATTENDEE_USER = 'ATTENDEE_USER',
  OBSERVER_USER = 'OBSERVER_USER',
}

export enum EUserGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NO_ANSWER = 'NO_ANSWER'
}
