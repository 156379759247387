
import Vue from 'vue';

export default Vue.extend({
  props: {
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteItemConfirm() {
      this.$emit('delete');
    },
  },
});
