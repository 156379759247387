import VueI18n from 'vue-i18n';
import {ApiService} from './api.service';
import {isDef} from '../static/control.service';

export interface IGetI18NReturn {
  vueI18n: any;
  i18nLanguageList: string[];
}

export class I18nApiService {
  public static async getI18n(): Promise<IGetI18NReturn> {
    const wordingList = await ApiService.get('/api/i18n/locize');
    const i18nLanguageList = isDef(wordingList) ? Object.keys(wordingList) : [];
    return {
      vueI18n: new VueI18n({
        locale: 'fr',
        fallbackLocale: 'en',
        messages: isDef(wordingList) ? wordingList : {},
      }),
      i18nLanguageList,
    };
  }

  public static async refreshI18n(): Promise<any> {
    const wordingList = await ApiService.post('/api/i18n/locize/reload');
    return wordingList;
  }
}
