import {ApiService} from '@/service/api/api.service';
import {IYoutubeMedia} from '@/interface/youtube.media.interface';
import {ICreateMediaDto} from '@/service/api/media/dto/create.media.dto';
import {IUpdateMediaDto} from '@/service/api/media/dto/update.media.dto';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IYoutubeMediaListResponseDto,
} from '@/service/api/media/dto/youtube.media.list.response.dto';
import {EActiveState} from '@/enum/active.enum';

const UPDATE_ORDERED_YOUTUBE_MEDIA_LIST_URI = '/api/admin/ordered-media/youtube';
const GET_YOUTUBE_MEDIA_LIST_URI = '/api/admin/media/youtube';
const NEW_YOUTUBE_MEDIA_URI = '/api/media/youtube/new';
const UPDATE_YOUTUBE_MEDIA_URI = (mediaUUID: string) => `/api/media/youtube/${mediaUUID}`;
const DELETE_YOUTUBE_MEDIA_URI = (mediaUUID: string) => `/api/media/youtube/${mediaUUID}`;

export class YoutubeMediaApiService {
  public static async getMediaList(
    args: IGenericListQuery,
  ): Promise<IYoutubeMediaListResponseDto> {
    return await ApiService.get(GET_YOUTUBE_MEDIA_LIST_URI, args);
  }

  public static async getFullActiveYoutubeMediaList(): Promise<IYoutubeMedia[]> {
    return (await ApiService.get(
      GET_YOUTUBE_MEDIA_LIST_URI,
      {
        pageNumber: 0,
        pageSize: -1,
        active: EActiveState.ACTIVE,
      },
    )).youtubeMediaList;
  }

  public static async updateFullActiveYoutubeMediaList(
    orderedList: IYoutubeMedia[],
  ): Promise<void> {
    await ApiService.post(UPDATE_ORDERED_YOUTUBE_MEDIA_LIST_URI, orderedList);
  }

  public static async createNewMedia(media: ICreateMediaDto): Promise<IYoutubeMedia> {
    return await ApiService.post(NEW_YOUTUBE_MEDIA_URI, media);
  }

  public static async updateMedia(
    mediaUUID: string,
    media: IUpdateMediaDto,
  ): Promise<IYoutubeMedia> {
    return await ApiService.post(UPDATE_YOUTUBE_MEDIA_URI(mediaUUID), media);
  }

  public static async deleteMedia(mediaUUID: string): Promise<void> {
    await ApiService.delete(DELETE_YOUTUBE_MEDIA_URI(mediaUUID));
  }
}
