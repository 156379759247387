
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: Array,
  },
  data() {
    return {
      activeFilterList: [...this.value],
    };
  },
  methods: {
    changed() {
      this.$emit('input', this.activeFilterList);
      this.$emit('activeFilterChanged');
    },
  },
});
