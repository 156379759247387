import axios, {AxiosError, AxiosResponse} from 'axios';
import store from '../../store';

export class ApiService {
  public static async get(url: string, params?: any): Promise<any> {
    const res: AxiosResponse | void = await axios.get(url, {params}).catch(ApiService.handleError);
    return ApiService.handleResponse(res);
  }

  public static async getByUUID(url: string, uuid?: any): Promise<any> {
    const res: AxiosResponse | void = await axios.get(url + uuid).catch(ApiService.handleError);
    return ApiService.handleResponse(res);
  }

  public static async post(url: string, data?: any) {
    return ApiService.handleResponse(await axios.post(url, data));
  }

  public static async put(url: string, data?: any) {
    return ApiService.handleResponse(await axios.put(url, data));
  }

  public static async delete(url: string, data?: any) {
    return ApiService.handleResponse(await axios.delete(url, {data}));
  }

  public static async patch(url: string, data?: any) {
    return ApiService.handleResponse(await axios.patch(url, data));
  }

  public static async handleResponse(res?: AxiosResponse | void): Promise<any> {
    return res instanceof Object && res?.data ? res.data : null;
  }

  public static async handleError(error: AxiosError) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log('[API-SERVICE][ERROR-RESPONSE][DATA]', error.response.data);
      console.log('[API-SERVICE][ERROR-RESPONSE][STATUS]', error.response.status);
      console.log('[API-SERVICE][ERROR-RESPONSE][HEADERS]', error.response.headers);

      // 401 | 403 === logout
      if (error.response.status === 401 || error.response.status === 403) {
        console.log('401401');
        await store.dispatch('logout');
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log('[API-SERVICE][ERROR-REQUEST]', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('[API-SERVICE][ERROR-SETTINGS-REQUEST]', error.message);
    }
    console.log('[API-SERVICE][ERROR-CONFIG]', error.config);
  }
}
