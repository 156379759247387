export const internalUserHeaderList = [
  {
    text: 'First name',
    value: 'firstName',
    sortable: false,
  },
  {
    text: 'Last name',
    value: 'lastName',
    sortable: false,
  },
  {
    text: 'Email',
    value: 'email',
    sortable: false,
  },
  {
    text: 'Role',
    value: 'role',
    sortable: false,
  },
  {
    text: 'Created at',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
