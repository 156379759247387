
import Vue from 'vue';
import {observerHeaders} from '@/views/subscription/subscription.headers.json';
import {formatDateTime} from '@/filters/format.date.time.filter';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      observerHeaders,
      showDialog: this.show,
      deviceIOSListHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Model',
          value: 'model',
        },
        {
          text: 'Language',
          value: 'language',
        },
        {
          text: 'Created at',
          value: 'createdAt',
        },
      ],
    };
  },
  filters: {
    formatDateTime,
  },
  methods: {
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
  },
});
