import {ApiService} from '@/service/api/api.service';
import {IObserverUserUpdateDto} from '@/service/api/user/observerUser/dto/observer.user.update.dto';
import {IObserverUser} from '@/interface/observer.user.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IObserverUserListResponseDto,
} from '@/service/api/user/observerUser/dto/observer.user.list.response.dto';
import {
  IDeleteGhostObserverUserDto,
} from '@/service/api/user/observerUser/dto/delete.ghost.observer.user.dto';
import {
  ObserverUserOnboardingResponseStatsDto,
} from '@/service/api/user/observerUser/dto/observer.user.onboarding.response.stats.dto';

const GET_OBSERVER_USER_LIST_URI = '/api/admin/observer-user';
const UPDATE_OBSERVER_USER_URI = (observerUserUUID: string) => `/api/observer-user/${observerUserUUID}`;

const DELETE_GHOST_OBSERVER_USER_URI = '/api/admin/observer-user/ghost';

const GET_OBSERVER_USER_ONBOARDING_STATS_URI = '/api/admin/observer-user/onboarding/stats';

export class ObserverUserApiService {
  public static async getObserverUserList(
    args: IGenericListQuery,
  ): Promise<IObserverUserListResponseDto> {
    return await ApiService.get(GET_OBSERVER_USER_LIST_URI, args);
  }

  public static async updateObserverUser(
    observerUserUUID: string,
    observerUserUpdateDto: IObserverUserUpdateDto,
  ): Promise<IObserverUser> {
    const observerUser: IObserverUser = await ApiService.post(
      UPDATE_OBSERVER_USER_URI(observerUserUUID),
      observerUserUpdateDto,
    );
    return observerUser;
  }

  public static async deleteGhostObserverUser(
    observerUserDeleteGhostDto: IDeleteGhostObserverUserDto,
  ): Promise<void> {
    return await ApiService.delete(DELETE_GHOST_OBSERVER_USER_URI, observerUserDeleteGhostDto);
  }

  public static async getObserverUserOnboardingStats():
    Promise<ObserverUserOnboardingResponseStatsDto> {
    const response = await ApiService.get(GET_OBSERVER_USER_ONBOARDING_STATS_URI);
    return response;
  }
}
