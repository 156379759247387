
import Vue from 'vue';
import {VForm} from '@/type/vform.type';
import {
  subscriptionTrialRuleListJson,
} from '@/components/subscription/subscription.trial.rule.list.json';
import {SubscriptionApiService} from '@/service/api/subscription/subscription.api.service';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      subscriptionTrialRuleListJson,
      valid: true,
    };
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      if (this.form.validate()) {
        await SubscriptionApiService.updateSubscriptionTrial(
          this.value.uuid,
          this.value.subscriptionTrial,
        );
        this.$emit('close');
      }
    },
  },
});
