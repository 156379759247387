
import Vue from 'vue';
import {VForm} from '@/type/vform.type';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      valid: true,
      booleanValues: [true, false, 'null'],
    };
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      if (this.form.validate()) {
        this.$emit('save');
      }
    },
  },
});
