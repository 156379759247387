
import Vue from 'vue';
import {EDevicePlatform} from '@/enum/device.enum';
import RecommendedAppView from '@/views/recommended-app/recommended-app-view.vue';
export default Vue.extend({
  components: {RecommendedAppView},
  data: () => ({
    devicePlatform: EDevicePlatform.IOS,
  }),
});
