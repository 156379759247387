import {IRecommendedApp} from '@/interface/recommended.app.interface';
import {RecommendedAppApiService} from '@/service/api/recommendedApp/recommended.app.api.service';
import {ICreateRecommendedAppDto} from '@/service/api/recommendedApp/dto/create.recommended.app.dto';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {IContext} from '@/store/interface/context.interface';
import {IRecommendedAppResponseDto} from '@/service/api/recommendedApp/dto/recommended.app.response.dto';
import {EDevicePlatform} from '@/enum/device.enum';

export enum ERecommendedAppStoreAttribute {
  RECOMMENDED_APP_LIST = 'recommendedAppList',
  FULL_ACTIVE_RECOMMENDED_APP_LIST = 'fullActiveRecommendedAppList',
  PAGE_COUNT = 'recommendedAppListPageCount',
  TOTAL_RECOMMENDED_APP = 'totalRecommendedApp',
}

export enum ERecommendedAppStoreAction {
  GET_RECOMMENDED_APP_LIST = 'getRecommendedAppListFromApi',
  GET_FULL_ACTIVE_RECOMMENDED_APP_LIST = 'getFullActiveRecommendedAppListFromApi',
  UPDATE_FULL_ACTIVE_RECOMMENDED_APP_LIST = 'updateFullActiveRecommendedAppList',
  CREATE_RECOMMENDED_APP = 'createRecommendedApp',
  UPDATE_RECOMMENDED_APP = 'updateRecommendedSApp',
  DELETE_RECOMMENDED_APP = 'deleteRecommendedApp',
}

export interface IRecommendedAppStoreState {
  [ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST]: IRecommendedApp[];
  [ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST]: IRecommendedApp[];
  [ERecommendedAppStoreAttribute.PAGE_COUNT]: number;
  [ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP]: number;
}

export const recommendedAppStore = {
  state: {
    [ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST]: [],
    [ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST]: [],
    [ERecommendedAppStoreAttribute.PAGE_COUNT]: 0,
    [ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP]: 0,
  },
  getters: {},
  mutations: {
    [ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST]: (
      state: IRecommendedAppStoreState,
      list: IRecommendedApp[],
    ) => {
      state[ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST] = list;
    },
    [ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST]: (
      state: IRecommendedAppStoreState,
      list: IRecommendedApp[],
    ) => {
      state[
        ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST
      ] = list;
    },
    [ERecommendedAppStoreAttribute.PAGE_COUNT]: (
      state: IRecommendedAppStoreState,
      pageCount: number,
    ) => {
      state[ERecommendedAppStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP]: (
      state: IRecommendedAppStoreState,
      total: number,
    ) => {
      state[ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP] = total;
    },
  },
  actions: {
    async [ERecommendedAppStoreAction.GET_RECOMMENDED_APP_LIST](
      context: IContext,
      args: IGenericListQuery & {devicePlatform: EDevicePlatform},
    ): Promise<void> {
      const apiResponse: IRecommendedAppResponseDto = await RecommendedAppApiService
        .getRecommendedAppListFromApi(args, args.devicePlatform);
      context.commit(
        ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST,
        apiResponse.recommendedAppList,
      );
      context.commit(ERecommendedAppStoreAttribute.PAGE_COUNT, apiResponse.pageCount);
      context.commit(ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP, apiResponse.totalCount);
    },

    async [ERecommendedAppStoreAction.GET_FULL_ACTIVE_RECOMMENDED_APP_LIST](
      context: IContext,
      devicePlatform: EDevicePlatform,
    ): Promise<void> {
      const apiResponse: IRecommendedApp[] = await RecommendedAppApiService
        .getFullActiveRecommendedAppList(devicePlatform);
      context.commit(
        ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST,
        apiResponse,
      );
    },

    async [ERecommendedAppStoreAction.UPDATE_FULL_ACTIVE_RECOMMENDED_APP_LIST](
      context: IContext,
      data: {recommendedAppList: IRecommendedApp[], devicePlatform: EDevicePlatform},
    ): Promise<void> {
      const res = await RecommendedAppApiService.updateFullActiveRecommendedAppList(
        data.recommendedAppList,
        data.devicePlatform,
      );
      return res;
    },

    async [ERecommendedAppStoreAction.CREATE_RECOMMENDED_APP](
      context: IContext,
      data: {recommendedApp: ICreateRecommendedAppDto, devicePlatform: EDevicePlatform},
    ): Promise<void> {
      await RecommendedAppApiService.newRecommendedApp(data.recommendedApp, data.devicePlatform);
    },

    async [ERecommendedAppStoreAction.UPDATE_RECOMMENDED_APP](
      context: IContext,
      data: {recommendedAppUUID: string, recommendedApp: Partial<IRecommendedApp>},
    ): Promise<IRecommendedApp> {
      return await RecommendedAppApiService.updateRecommendedApp(
        data.recommendedAppUUID,
        data.recommendedApp,
      );
    },

    async [ERecommendedAppStoreAction.DELETE_RECOMMENDED_APP](
      context: IContext,
      data: {recommendedAppUUID: string},
    ): Promise<void> {
      return await RecommendedAppApiService.deleteRecommendedApp(data.recommendedAppUUID);
    },
  },
};
