
import Vue from 'vue';
import {
  adminAppParametersStore,
  EAdminAppParametersStoreAttribute,
} from '@/store/admin.app.parameters.store';
import {IAdminAppParameters} from '@/interface/admin.app.parameters.interface';
import DialogUpdateAdminTrialDuration
  from '@/components/admin-app-parameters/dialog-update-admin-trial-duration.vue';
import DialogUpdateAdminCollectObserverPhone
  from '@/components/admin-app-parameters/dialog-update-admin-collect-observer-phone.vue';
import DialogUpdateAdminDisplayRatings from '@/components/admin-app-parameters/dialog-update-admin-display-ratings.vue';
import DialogUpdateAdminDisplayPostPaywall
  from '@/components/admin-app-parameters/dialog-update-admin-display-post-paywall.vue';
import DialogUpdateAdminSoftModeDefaultPercent
  from '@/components/admin-app-parameters/dialog-update-admin-soft-mode-default-percent.vue';
import DialogUpdateAdminDisplaySoftModeInAddUpOnboarding
  from '@/components/admin-app-parameters/dialog-update-admin-display-soft-mode-in-add-up-onboarding.vue';
import DialogUpdateAdminDisplayNewCustomProgram
  from '@/components/admin-app-parameters/dialog-update-admin-display-new-custom-program.vue';

export default Vue.extend({
  name: 'AdminAppParametersView',
  data: () => ({
    showUpdateTrialDurationDialog: false,
    showUpdateCollectObserverUserPhoneDialog: false,
    showUpdateHasToAskForRatings: false,
    showUpdateHasToDisplayPostPaywallProcess: false,
    showUpdateSoftModeDefaultPercentDialog: false,
    showUpdateHasToDisplaySoftModeInAddUpDialog: false,
    showUpdateHasToDisplayNewCustomProgramViewDialog: false,
    editedTrialDurationDays: {trialDurationDays: 0},
    editedHasToCollectObserverUserPhoneNumber: {},
    editedHasToAskForRatings: {},
    editedHasToDisplayPostPaywallProcess: {},
    editedSoftModeDefaultPercent: {softModeDefaultPercent: 0},
    editedHasToDisplaySoftModeInAddUp: {},
    editedHasToDisplayNewCustomProgramView: {},
    headers: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Value',
        value: 'value',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  components: {
    DialogUpdateAdminDisplayNewCustomProgram,
    DialogUpdateAdminDisplaySoftModeInAddUpOnboarding,
    DialogUpdateAdminSoftModeDefaultPercent,
    DialogUpdateAdminDisplayPostPaywall,
    DialogUpdateAdminDisplayRatings,
    DialogUpdateAdminTrialDuration,
    DialogUpdateAdminCollectObserverPhone,
  },
  async mounted() {
    await this.getDataFromApi();
  },
  computed: {
    adminAppParameters() {
      const appParameters: IAdminAppParameters = adminAppParametersStore
        .state[EAdminAppParametersStoreAttribute.ADMIN_APP_PARAMETERS];
      return [
        {
          name: 'Trial duration (days)',
          value: appParameters.trialDurationDays,
          identifier: 'trialDurationDays',
        },
        {
          name: 'Collect observer user phone number',
          value: appParameters.hasToCollectObserverUserPhoneNumber,
          identifier: 'hasToCollectObserverUserPhoneNumber',
        },
        {
          name: 'Display ratings feature',
          value: appParameters.hasToAskForRatings,
          identifier: 'hasToAskForRatings',
        },
        {
          name: 'Display post paywall feature',
          value: appParameters.hasToDisplayPostPaywallProcess,
          identifier: 'hasToDisplayPostPaywallProcess',
        },
        {
          name: 'Set default soft mode / time advance percent',
          value: appParameters.softModeDefaultPercent,
          identifier: 'softModeDefaultPercent',
        },
        {
          name: 'Display soft mode / time advance feature in ADD UP and ON BOARDING processes',
          value: appParameters.hasToDisplaySoftModeInAddUp,
          identifier: 'hasToDisplaySoftModeInAddUp',
        },
        {
          name: 'Display new custom program feature in ADD UP and ON BOARDING processes',
          value: appParameters.hasToDisplayNewCustomProgramView,
          identifier: 'hasToDisplayNewCustomProgramView',
        },
      ];
    },
  },
  methods: {
    openUpdateDialog(item: any) {
      if (item.identifier === 'trialDurationDays') {
        this.editedTrialDurationDays = {trialDurationDays: item.value};
        this.showUpdateTrialDurationDialog = true;
      } else if (item.identifier === 'hasToCollectObserverUserPhoneNumber') {
        this.editedHasToCollectObserverUserPhoneNumber = {
          hasToCollectObserverUserPhoneNumber: item.value,
        };
        this.showUpdateCollectObserverUserPhoneDialog = true;
      } else if (item.identifier === 'hasToAskForRatings') {
        this.editedHasToAskForRatings = {
          hasToAskForRatings: item.value,
        };
        this.showUpdateHasToAskForRatings = true;
      } else if (item.identifier === 'hasToDisplayPostPaywallProcess') {
        this.editedHasToDisplayPostPaywallProcess = {
          hasToDisplayPostPaywallProcess: item.value,
        };
        this.showUpdateHasToDisplayPostPaywallProcess = true;
      } else if (item.identifier === 'softModeDefaultPercent') {
        this.editedSoftModeDefaultPercent = {
          softModeDefaultPercent: item.value,
        };
        this.showUpdateSoftModeDefaultPercentDialog = true;
      } else if (item.identifier === 'hasToDisplaySoftModeInAddUp') {
        this.editedHasToDisplaySoftModeInAddUp = {
          hasToDisplaySoftModeInAddUp: item.value,
        };
        this.showUpdateHasToDisplaySoftModeInAddUpDialog = true;
      } else if (item.identifier === 'hasToDisplayNewCustomProgramView') {
        this.editedHasToDisplayNewCustomProgramView = {
          hasToDisplayNewCustomProgramView: item.value,
        };
        this.showUpdateHasToDisplayNewCustomProgramViewDialog = true;
      }
    },
    async closeUpdateTrialDurationDialog() {
      this.showUpdateTrialDurationDialog = false;
    },
    async closeUpdateCollectObserverUserPhoneDialog() {
      this.showUpdateCollectObserverUserPhoneDialog = false;
    },
    async closeUpdateHasToAskForRatingsDialog() {
      this.showUpdateHasToAskForRatings = false;
    },
    async closeUpdateHasToDisplayPostPaywallProcessDialog() {
      this.showUpdateHasToDisplayPostPaywallProcess = false;
    },
    async closeUpdateSoftModeDefaultPercentDialogDialog() {
      this.showUpdateSoftModeDefaultPercentDialog = false;
    },
    async closeUpdateHasToDisplaySoftModeInAddUpDialog() {
      this.showUpdateHasToDisplaySoftModeInAddUpDialog = false;
    },
    async closeUpdateHasToDisplayNewCustomProgramViewDialog() {
      this.showUpdateHasToDisplayNewCustomProgramViewDialog = false;
    },
    async getDataFromApi() {
      await this.$store.dispatch('getAdminAppParameters');
    },
  },
});
