
import Vue from 'vue';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {IAttendeeUser} from '@/interface/attendee.user.interface';
import {attendeeUserStore, EAttendeeUserStoreAttribute} from '@/store/user/attendee.user.store';
import DialogUpdateAttendeeUser from '@/components/user/dialog-update-attendee-user.vue';
import DialogDelete from '@/components/dialog-delete.vue';
import {IAttendeeUserMediation} from '@/interface/mediation.interface';
import DialogUpdateMediation from '@/components/user/dialog-update-mediation.vue';
import {IAttendeeUserMediationStep} from '@mr-arthur/ma-api/package-registry/dist/src/TypeORM/MA';
import DialogUpdateLatestMediationStep
  from '@/components/user/dialog-update-latest-mediation-step.vue';
import {footerProps} from '@/views/table.const';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {formatDate} from '@/filters/format.date.filter';

export default Vue.extend({
  data: () => ({
    options: {} as IPaginationOptions,
    editedItem: {} as Partial<IAttendeeUser>,
    editedMediation: {} as Partial<IAttendeeUserMediation>,
    editedMediationStep: {} as Partial<IAttendeeUserMediationStep>,
    editedIndex: -1,
    isLoading: false,
    footerProps,
    expanded: [],
    search: '',
    showUpdateAttendeeDialog: false,
    showDeleteDialog: false,
    showUpdateMediationDialog: false,
    showUpdateMediationStepDialog: false,
    deviceIOSListHeaders: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Model',
        value: 'model',
      },
      {
        text: 'Language',
        value: 'language',
      },
    ],
    screenTimeCommitmentHeaders: [
      {
        text: 'Entertainment screen time allowed (s)',
        value: 'entertainmentScreenTimeSecondAllowed',
      },
      {
        text: 'Educational screen time commitment (s)',
        value: 'educationalScreenTimeSecondCommitment',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    stepListHeaders: [
      {
        text: 'Time reduction (%)',
        value: 'totalTimeReductionPercent',
      },
      {
        text: 'Educative time (%)',
        value: 'educativeTimePercent',
      },
      {
        text: 'Total time (mins)',
        value: 'totalTimeMinutes',
      },
      {
        text: 'Educative time (mins)',
        value: 'educativeTimeMinutes',
      },
      {
        text: 'Entertainment time (mins)',
        value: 'entertainmentTimeMinutes',
      },
      {
        text: 'SmartPhone (mins)',
        value: 'estimatedSmartPhoneMinutes',
      },
      {
        text: 'Tablet (mins)',
        value: 'estimatedTabletMinutes',
      },
      {
        text: 'Accepted',
        value: 'accepted',
      },
    ],
    estimationHeaders: [
      {
        text: 'Total screen (mins)',
        value: 'totalScreenTimeMinutes',
      },
      {
        text: 'Daily TV (mins)',
        value: 'dailyTVMinutes',
      },
      {
        text: 'Daily video games (mins)',
        value: 'dailyVideoGameMinutes',
      },
      {
        text: 'Daily smartphone (mins)',
        value: 'dailyOwnSmartPhoneMinutes',
      },
      {
        text: 'Daily tablet (mins)',
        value: 'dailyOwnTabletMinutes',
      },
      {
        text: 'Daily educative (mins)',
        value: 'dailyEducativeTimeMinutes',
      },
      {
        text: 'Diagnosed screen time (mins)',
        value: 'diagnosedScreenTimeMinutes',
      },
    ],
    attendeeHeaders: [
      {
        text: 'Name',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Gender',
        value: 'gender',
        sortable: false,
      },
      {
        text: 'Birth date',
        value: 'birthDate',
        sortable: false,
      },
      {
        text: 'Number of devices',
        value: 'nDevice',
        sortable: false,
      },
      {
        text: 'Rest period',
        value: 'restPeriod',
        sortable: false,
      },
      {
        text: 'Preset',
        value: 'preset',
        sortable: false,
      },
      {
        text: 'Mediation state',
        value: 'mediationState',
        sortable: false,
      },
      {
        text: 'Number of snapshots',
        value: 'nSnapshots',
        sortable: false,
      },
      {
        text: 'Observer user email',
        value: 'observerUserEmail',
        sortable: false,
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  filters: {
    formatDate,
    formatDateTime,
  },
  components: {
    DialogUpdateAttendeeUser,
    DialogDelete,
    DialogUpdateMediation,
    DialogUpdateLatestMediationStep,
  },
  computed: {
    totalAttendee(): number {
      return attendeeUserStore.state[EAttendeeUserStoreAttribute.TOTAL_ATTENDEE_USER];
    },
    attendeeUserList(): IAttendeeUser[] {
      return attendeeUserStore.state[EAttendeeUserStoreAttribute.ATTENDEE_USER_LIST];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New attendee user' : 'Edit attendee user';
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  methods: {
    formatDateTime,
    openUpdateDialog(item: IAttendeeUser) {
      /* eslint-disable no-param-reassign */
      item.birthDate = new Date(item.birthDate).toISOString().slice(0, 10);
      this.setEditedItem(item);
      this.showUpdateAttendeeDialog = true;
    },
    closeUpdateAttendeeDialog() {
      this.showUpdateAttendeeDialog = false;
    },
    setEditedItem(item: IAttendeeUser) {
      this.editedItem = {...item};
      this.editedIndex = this.attendeeUserList.indexOf(item);
    },
    openDeleteDialog(item: IAttendeeUser) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(
        'deleteAttendeeUser',
        {attendeeUserUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },

    openUpdateMediationDialog(item: IAttendeeUserMediation) {
      this.editedMediation = {...item};
      this.showUpdateMediationDialog = true;
    },
    closeUpdateMediationDialog() {
      this.showUpdateMediationDialog = false;
    },

    openUpdateMediationStepDialog(
      mediation: IAttendeeUserMediation,
    ) {
      this.editedMediation = {...mediation};
      this.editedMediationStep = {
        ...this.editedMediation.stepListJsonb.list[
          this.editedMediation.stepListJsonb.list.length - 1
        ],
      };
      this.showUpdateMediationStepDialog = true;
    },
    closeUpdateMediationStepDialog() {
      this.showUpdateMediationStepDialog = false;
    },

    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
        'getAttendeeUserList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
        },
      );
      this.isLoading = false;
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          'updateAttendeeUser',
          {
            attendeeUserUUID: this.editedItem.uuid,
            args: {
              attendeeName: this.editedItem.name,
              gender: this.editedItem.gender,
              birthDate: this.editedItem.birthDate,
            },
          },
        );
        await this.$store.dispatch(
          'updateAttendeeUserPreset',
          {
            attendeeUserUUID: this.editedItem.uuid,
            args: {
              preset: this.editedItem.preset,
            },
          },
        );
        await this.$store.dispatch(
          'updateAttendeeUserRestPeriod',
          {
            attendeeUserUUID: this.editedItem.uuid,
            args: {
              restPeriod: this.editedItem.restPeriod,
            },
          },
        );
      }
      this.closeUpdateAttendeeDialog();
      await this.getDataFromApi();
    },

    async saveMediation() {
      await this.$store.dispatch(
        'updateAttendeeUserMediation',
        {
          mediationUUID: this.editedMediation.uuid,
          args: {
            entertainmentScreenTimeSecondAllowed: this.editedMediation
              .entertainmentScreenTimeSecondAllowed,
            educationalScreenTimeSecondCommitment: this.editedMediation
              .educationalScreenTimeSecondCommitment,
          },
        },
      );
      this.closeUpdateMediationDialog();
      await this.getDataFromApi();
    },

    async acceptAttendeeUserMediationLatestStep() {
      if (this.editedMediationStep.accepted === 'null') {
        this.editedMediationStep.accepted = null;
      }
      await this.$store.dispatch(
        'acceptAttendeeUserMediationLatestStep',
        {
          mediationUUID: this.editedMediation.uuid,
          args: {
            accepted: this.editedMediationStep.accepted,
          },
        },
      );
      this.closeUpdateMediationStepDialog();
      await this.getDataFromApi();
    },
  },
});
