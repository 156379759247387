import {IAppCategory} from '@/interface/category.interface';
import {IContext} from '@/store/interface/context.interface';
import {AppCategoryApiService} from '@/service/api/recommendedApp/app.category.api.service';

export enum EAppCategoryStoreAttribute {
  APP_CATEGORY_LIST = 'appCategoryList',
}

export interface IAppCategoryStoreState {
  [EAppCategoryStoreAttribute.APP_CATEGORY_LIST]: IAppCategory[];
}

export const appCategoryStore = {
  state: {
    [EAppCategoryStoreAttribute.APP_CATEGORY_LIST]: [],
  },
  getters: {},
  mutations: {
    [EAppCategoryStoreAttribute.APP_CATEGORY_LIST]: (
      state: IAppCategoryStoreState,
      appCategoryList: IAppCategory[],
    ) => {
      state[EAppCategoryStoreAttribute.APP_CATEGORY_LIST] = appCategoryList;
    },
  },
  actions: {
    async getAppCategoryList(context: IContext): Promise<void> {
      const apiResponse = await AppCategoryApiService.getAppCategoryList();
      context.commit(EAppCategoryStoreAttribute.APP_CATEGORY_LIST, apiResponse);
    },

    async createAppCategory(
      context: IContext,
      data: {appCategory: Partial<IAppCategory>},
    ): Promise<void> {
      await AppCategoryApiService.createAppCategory(data.appCategory);
    },

    async updateAppCategory(
      context: IContext,
      data: {appCategoryUUID: string; dataToUpdate: Partial<IAppCategory>},
    ): Promise<void> {
      await AppCategoryApiService.updateAppCategory(
        data.appCategoryUUID,
        data.dataToUpdate,
      );
    },

    async deleteAppCategory(
      context: IContext,
      data: {appCategoryUUID: string},
    ): Promise<void> {
      await AppCategoryApiService.deleteAppCategory(data.appCategoryUUID);
    },
  },
};
