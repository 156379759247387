import {ObserverUserApiService} from '@/service/api/user/observerUser/observer.user.api.service';
import {IObserverUserUpdateDto} from '@/service/api/user/observerUser/dto/observer.user.update.dto';
import {IContext} from '@/store/interface/context.interface';
import {IObserverUser} from '@/interface/observer.user.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IObserverUserListResponseDto,
} from '@/service/api/user/observerUser/dto/observer.user.list.response.dto';

export enum EObserverUserStoreAttribute {
  OBSERVER_USER_LIST = 'observerUserList',
  PAGE_COUNT = 'pageCount',
  TOTAL_OBSERVER_USER = 'totalObserverUser',
  OBSERVER_USER_ONBOARDING_STATS = 'observerUserOnboardingStats',
}

export interface IObserverUserStoreState {
  [EObserverUserStoreAttribute.OBSERVER_USER_LIST]: IObserverUser[];
  [EObserverUserStoreAttribute.PAGE_COUNT]: number;
  [EObserverUserStoreAttribute.TOTAL_OBSERVER_USER]: number;
  [EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS]: any;
}

export const observerUserStore = {
  state: {
    [EObserverUserStoreAttribute.OBSERVER_USER_LIST]: [],
    [EObserverUserStoreAttribute.PAGE_COUNT]: 0,
    [EObserverUserStoreAttribute.TOTAL_OBSERVER_USER]: 0,
    [EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS]: {},
  },
  getters: {},
  mutations: {
    [EObserverUserStoreAttribute.OBSERVER_USER_LIST]: (
      state: IObserverUserStoreState,
      observerUserList: IObserverUser[],
    ) => {
      state[EObserverUserStoreAttribute.OBSERVER_USER_LIST] = observerUserList;
    },
    [EObserverUserStoreAttribute.PAGE_COUNT]: (
      state: IObserverUserStoreState,
      pageCount: number,
    ) => {
      state[EObserverUserStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EObserverUserStoreAttribute.TOTAL_OBSERVER_USER]: (
      state: IObserverUserStoreState,
      totalObserverUser: number,
    ) => {
      state[EObserverUserStoreAttribute.TOTAL_OBSERVER_USER] = totalObserverUser;
    },
    [EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS]: (
      state: IObserverUserStoreState,
      stats: any,
    ) => {
      state[EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS] = stats;
    },
  },
  actions: {
    async getObserverUserList(context: IContext, args: IGenericListQuery): Promise<void> {
      const apiResponse: IObserverUserListResponseDto = await ObserverUserApiService
        .getObserverUserList(args);
      context.commit(EObserverUserStoreAttribute.OBSERVER_USER_LIST, apiResponse.observerUserList);
      context.commit(EObserverUserStoreAttribute.PAGE_COUNT, apiResponse.pageCount);
      context.commit(EObserverUserStoreAttribute.TOTAL_OBSERVER_USER, apiResponse.totalCount);
    },

    async updateObserverUser(
      context: IContext,
      data: {
        observerUserUUID: string,
        observerUserData: IObserverUserUpdateDto,
      },
    ): Promise<IObserverUser> {
      return await ObserverUserApiService.updateObserverUser(
        data.observerUserUUID,
        data.observerUserData,
      );
    },

    async getObserverUserOnboardingStats(
      context: IContext,
    ) {
      const apiResponse = await ObserverUserApiService.getObserverUserOnboardingStats();
      context.commit(EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS, apiResponse);
    },
  },
};
