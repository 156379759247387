import {isDef} from '@/service/static/control.service';
import {YoutubeMediaApiService} from '@/service/api/media/youtube.media.api.service';
import {ICreateMediaDto} from '@/service/api/media/dto/create.media.dto';
import {IUpdateMediaDto} from '@/service/api/media/dto/update.media.dto';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IYoutubeMediaListResponseDto,
} from '@/service/api/media/dto/youtube.media.list.response.dto';
import {IYoutubeMedia} from '@/interface/youtube.media.interface';
import {IContext} from '@/store/interface/context.interface';

export enum EYoutubeMediaStoreAttribute {
  YOUTUBE_MEDIA_LIST = 'youtubeMediaList',
  FULL_ACTIVE_YOUTUBE_MEDIA_LIST = 'fullActiveYoutubeMediaList',
  PAGE_COUNT = 'pageCount',
  TOTAL_YOUTUBE_MEDIA = 'totalYoutubeMedia',
}

export interface IYoutubeMediaStoreState {
  [EYoutubeMediaStoreAttribute.YOUTUBE_MEDIA_LIST]: IYoutubeMedia[];
  [EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST]: IYoutubeMedia[];
  [EYoutubeMediaStoreAttribute.PAGE_COUNT]: number;
  [EYoutubeMediaStoreAttribute.TOTAL_YOUTUBE_MEDIA]: number;
}

export const youtubeMediaStore = {
  state: {
    [EYoutubeMediaStoreAttribute.YOUTUBE_MEDIA_LIST]: [],
    [EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST]: [],
    [EYoutubeMediaStoreAttribute.PAGE_COUNT]: 0,
    [EYoutubeMediaStoreAttribute.TOTAL_YOUTUBE_MEDIA]: 0,
  },
  getters: {},
  mutations: {
    [EYoutubeMediaStoreAttribute.YOUTUBE_MEDIA_LIST]: (
      state: IYoutubeMediaStoreState,
      mediaList: IYoutubeMedia[],
    ) => {
      state[EYoutubeMediaStoreAttribute.YOUTUBE_MEDIA_LIST] = mediaList;
    },
    [EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST]: (
      state: IYoutubeMediaStoreState,
      mediaList: IYoutubeMedia[],
    ) => {
      state[EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST] = mediaList;
    },
    [EYoutubeMediaStoreAttribute.PAGE_COUNT]: (
      state: IYoutubeMediaStoreState,
      pageCount: number,
    ) => {
      state[EYoutubeMediaStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EYoutubeMediaStoreAttribute.TOTAL_YOUTUBE_MEDIA]: (
      state: IYoutubeMediaStoreState,
      totalMedia: number,
    ) => {
      state[EYoutubeMediaStoreAttribute.TOTAL_YOUTUBE_MEDIA] = totalMedia;
    },
  },
  actions: {
    async getMediaList(context: IContext, args: IGenericListQuery): Promise<void> {
      const apiResponse: IYoutubeMediaListResponseDto = await YoutubeMediaApiService
        .getMediaList(args);
      context.commit('youtubeMediaList', apiResponse.youtubeMediaList);
      context.commit('pageCount', apiResponse.pageCount);
      context.commit('totalYoutubeMedia', apiResponse.totalCount);
    },
    async getFullActiveYoutubeMediaListFromApi(
      context: IContext,
    ): Promise<void> {
      const apiResponse: IYoutubeMedia[] = await YoutubeMediaApiService
        .getFullActiveYoutubeMediaList();
      context.commit(
        EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST,
        apiResponse,
      );
    },

    async createMedia(context: IContext, data: {mediaData: IYoutubeMedia}): Promise<void> {
      const mediaToCreate: ICreateMediaDto = {
        ...data.mediaData,
        durationHour: parseInt(data.mediaData.durationHour, 10),
        durationMinute: parseInt(data.mediaData.durationMinute, 10),
        durationSecond: parseInt(data.mediaData.durationSecond, 10),
      };
      await YoutubeMediaApiService.createNewMedia(mediaToCreate);
    },

    async updateFullActiveYoutubeMediaList(
      context: IContext,
      data: {orderedList: IYoutubeMedia[]},
    ): Promise<void> {
      await YoutubeMediaApiService.updateFullActiveYoutubeMediaList(
        data.orderedList,
      );
    },

    async updateMedia(
      context: IContext,
      data: {mediaUUID: string, mediaData: Partial<IYoutubeMedia>},
    ): Promise<IYoutubeMedia> {
      const {
        durationHour, durationMinute, durationSecond, ...mediaDto
      } = data.mediaData;
      if (isDef(durationHour)) {
        (mediaDto as IUpdateMediaDto).durationHour = parseInt(durationHour as string, 10);
      }
      if (isDef(durationMinute)) {
        (mediaDto as IUpdateMediaDto).durationMinute = parseInt(durationMinute as string, 10);
      }
      if (isDef(durationSecond)) {
        (mediaDto as IUpdateMediaDto).durationSecond = parseInt(durationSecond as string, 10);
      }
      return await YoutubeMediaApiService.updateMedia(data.mediaUUID, mediaDto);
    },
    async deleteMedia(
      context: IContext,
      data: {mediaUUID: string},
    ): Promise<void> {
      return await YoutubeMediaApiService.deleteMedia(data.mediaUUID);
    },
  },
};
