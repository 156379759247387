
import Vue from 'vue';
import onboardingPercentagePerAnswerCard
  from '@/components/user/onboarding-percentage-per-answer-card.vue';
import {EObserverUserStoreAttribute, observerUserStore} from '@/store/user/observer.user.store';
import {attendeeUserStore, EAttendeeUserStoreAttribute} from '@/store/user/attendee.user.store';

export default Vue.extend({
  data: () => ({
  }),
  components: {
    onboardingPercentagePerAnswerCard,
  },
  async mounted() {
    await this.getDataFromApi();
  },
  computed: {
    observerQuestionList() {
      return observerUserStore.state[EObserverUserStoreAttribute.OBSERVER_USER_ONBOARDING_STATS];
    },
    attendeeQuestionList() {
      return attendeeUserStore.state[EAttendeeUserStoreAttribute.ATTENDEE_USER_ONBOARDING_STATS];
    },
  },
  methods: {
    async getDataFromApi() {
      await this.$store.dispatch('getObserverUserOnboardingStats');
      await this.$store.dispatch('getAttendeeUserOnboardingStats');
    },
  },
});
