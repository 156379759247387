import {IContext} from '@/store/interface/context.interface';
import {IMediaCategory} from '@/interface/category.interface';
import {MediaCategoryApiService} from '@/service/api/media/media.category.api.service';

export enum EMediaCategoryStoreAttribute {
  MEDIA_CATEGORY_LIST = 'mediaCategoryList',
}

export interface IMediaCategoryStoreState {
  [EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST]: IMediaCategory[];
}

export const mediaCategoryStore = {
  state: {
    [EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST]: [],
  },
  getters: {},
  mutations: {
    [EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST]: (
      state: IMediaCategoryStoreState,
      mediaCategoryList: IMediaCategory[],
    ) => {
      state[EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST] = mediaCategoryList;
    },
  },
  actions: {
    async getMediaCategoryList(context: IContext): Promise<void> {
      const apiResponse = await MediaCategoryApiService.getMediaCategoryList();
      context.commit(EMediaCategoryStoreAttribute.MEDIA_CATEGORY_LIST, apiResponse);
    },

    async createMediaCategory(
      context: IContext,
      data: {mediaCategory: Partial<IMediaCategory>},
    ): Promise<void> {
      await MediaCategoryApiService.createMediaCategory(data.mediaCategory);
    },

    async updateMediaCategory(
      context: IContext,
      data: {mediaCategoryUUID: string; dataToUpdate: Partial<IMediaCategory>},
    ): Promise<void> {
      await MediaCategoryApiService.updateMediaCategory(
        data.mediaCategoryUUID,
        data.dataToUpdate,
      );
    },

    async deleteMediaCategory(
      context: IContext,
      data: {mediaCategoryUUID: string},
    ): Promise<void> {
      await MediaCategoryApiService.deleteMediaCategory(data.mediaCategoryUUID);
    },
  },
};
