import Vue from 'vue';
import Vuex from 'vuex';
import {youtubeMediaStore} from '@/store/youtube.media.store';
import {recommendedIosAppStore} from '@/store/recommended.ios.app.store';
import {observerUserStore} from '@/store/user/observer.user.store';
import {internalUserStore} from '@/store/user/internal.user.store';
import {appCategoryStore} from '@/store/app.category.store';
import {mediaCategoryStore} from '@/store/media.category.store';
import {subscriptionStore} from '@/store/subscription.store';
import {adminAppParametersStore} from '@/store/admin.app.parameters.store';
import {screenTimeStore} from '@/store/screen.time.store';
import {attendeeUserStore} from '@/store/user/attendee.user.store';
import {deviceStore} from '@/store/device.store';
import {currentUserStore} from './user/current.user.store';
import {recommendedAppStore} from '@/store/recommended.app.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    media: youtubeMediaStore,
    recommendedIosApp: recommendedIosAppStore,
    user: currentUserStore,
    observerUser: observerUserStore,
    internalUser: internalUserStore,
    appCategory: appCategoryStore,
    mediaCategory: mediaCategoryStore,
    subscription: subscriptionStore,
    adminAppParameters: adminAppParametersStore,
    screenTime: screenTimeStore,
    attendeeUser: attendeeUserStore,
    device: deviceStore,
    recommendedAppStore: recommendedAppStore,
  },
});
