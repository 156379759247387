
import Vue from 'vue';
import {IRecommendedApp} from '@/interface/recommended.app.interface';
import {EActiveState} from '@/enum/active.enum';
import {recommendedIosAppHeaderList} from '@/views/recommended-app/recommended.ios.app.header.list';
import ActiveFilter from '@/components/active-filter.vue';
import DialogDelete from '@/components/dialog-delete.vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {ECountry} from '@/enum/country.enum';
import {EDevicePlatform, EDeviceType} from '@/enum/device.enum';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import DialogCreateUpdate from '@/components/recommended-ios-app/dialog-create-update.vue';
import DialogOrder from '@/components/recommended-ios-app/dialog-order.vue';
import {IAppCategory} from '@/interface/category.interface';
import {footerProps} from '@/views/table.const';
// import {ERouteParameter} from '@/router/router.enum';
import {
  ERecommendedAppStoreAction,
  ERecommendedAppStoreAttribute,
  recommendedAppStore,
} from '@/store/recommended.app.store';

export default Vue.extend({
  props: {
    devicePlatform: {
      type: () => EDevicePlatform,
    },
  },
  created() {
    console.log('created', this.devicePlatform);
  },
  data: () => ({
    footerProps,
    options: {} as IPaginationOptions,
    expanded: [],
    activeFilterList: [EActiveState.ACTIVE],
    search: '',
    showOrderDialog: false,
    showDeleteDialog: false,
    showCreateUpdateDialog: false,
    editedIndex: -1,
    isLoading: false,
    editedItem: {
      active: EActiveState.ACTIVE,
    } as Partial<IRecommendedApp>,
    defaultItem: {
      name: '',
      comment: '',
      deviceTypeList: [] as EDeviceType[],
      countryList: [] as ECountry[],
      appCategoryListId: [] as number[],
      link: '',
      urlScheme: '',
      active: EActiveState.ACTIVE,
    } as Partial<IRecommendedApp>,
    headers: recommendedIosAppHeaderList,
  }),
  components: {
    ActiveFilter,
    DialogDelete,
    DialogCreateUpdate,
    DialogOrder,
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  filters: {
    formatDateTime,
  },
  beforeCreate() {
    this.$store.commit(ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST, []);
    this.$store.commit(ERecommendedAppStoreAttribute.RECOMMENDED_APP_LIST, []);
    this.$store.commit(ERecommendedAppStoreAttribute.PAGE_COUNT, 0);
    this.$store.commit(ERecommendedAppStoreAttribute.TOTAL_RECOMMENDED_APP , 0);
  },
  methods: {
    activeState(): any {
      return this.activeFilterList.length === 1 ? this.activeFilterList[0] : null;
    },
    openCreateDialog() {
      this.editedItem = {...this.defaultItem};
      this.editedIndex = -1;
      this.openCreateUpdateDialog();
    },
    openUpdateDialog(item: IRecommendedApp) {
      this.setEditedItem(item);
      this.openCreateUpdateDialog();
    },
    async openOrderDialog() {
      await this.$store.dispatch(
          ERecommendedAppStoreAction.GET_FULL_ACTIVE_RECOMMENDED_APP_LIST,
          this.devicePlatform,
      );
      this.showOrderDialog = true;
    },
    closeOrderDialog() {
      this.showOrderDialog = false;
    },
    async saveOrderDialog() {
      await this.getDataFromApi();
      this.showOrderDialog = false;
    },
    openCreateUpdateDialog() {
      this.showCreateUpdateDialog = true;
    },
    closeCreateUpdateDialog() {
      this.showCreateUpdateDialog = false;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    openDeleteDialog(item: IRecommendedApp) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    setEditedItem(item: IRecommendedApp) {
      this.editedItem = {...item};
      this.editedIndex = this.recommendedAppList.indexOf(item);
    },
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
          ERecommendedAppStoreAction.UPDATE_FULL_ACTIVE_RECOMMENDED_APP_LIST,
          this.devicePlatform,
      );
      const payload = {
        pageNumber: this.options.page - 1 ?? 0,
        pageSize: this.options.itemsPerPage ?? 10,
        search: this.search,
        active: this.activeState(),
        devicePlatform: this.devicePlatform,
      };
      await this.$store.dispatch(
          ERecommendedAppStoreAction.GET_RECOMMENDED_APP_LIST, payload
      );
      this.isLoading = false;
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(
          ERecommendedAppStoreAction.DELETE_RECOMMENDED_APP,
          {recommendedAppUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          ERecommendedAppStoreAction.UPDATE_RECOMMENDED_APP,
          {recommendedAppUUID: this.editedItem.uuid, recommendedApp: this.editedItem},
        );
      } else {
        await this.$store.dispatch(
          ERecommendedAppStoreAction.CREATE_RECOMMENDED_APP,
          {recommendedApp: this.editedItem, devicePlatform: this.devicePlatform},
        );
      }
      this.closeCreateUpdateDialog();
      await this.getDataFromApi();
    },
  },
  computed: {
    totalRecommendedApp(): number {
      return recommendedAppStore.state.totalRecommendedApp;
    },
    recommendedAppList(): IRecommendedApp[] {
      const appList: IRecommendedApp[] = recommendedAppStore.state.recommendedAppList;
      const formattedAppList: any = [];
      appList.forEach((app: any) => {
        const formattedApp = JSON.parse(JSON.stringify(app));
        formattedApp.appCategoryEnNameList = formattedApp.appCategoryList
            .map((cat: IAppCategory) => cat.enName);
        formattedApp.appCategoryIdList = formattedApp.appCategoryList
            .map((cat: IAppCategory) => cat.id);
        formattedAppList.push(formattedApp);
      });
      return formattedAppList;
    },
    fullRecommendedAppList(): IRecommendedApp[] {
      return recommendedAppStore
          .state[ERecommendedAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_APP_LIST];
    },
    formTitle() {
      return this.editedIndex === -1
          ? `New recommended ${this.devicePlatform} app`
          : `Edit recommended ${this.devicePlatform} app`;
    },
  },
});
