import {ISubscription} from '@/interface/subscription.interface';
import {IContext} from '@/store/interface/context.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  ISubscriptionListResponseDto,
} from '@/service/api/subscription/dto/subscription.list.response.dto';
import {SubscriptionApiService} from '@/service/api/subscription/subscription.api.service';

export enum ESubscriptionStoreAttribute {
  SUBSCRIPTION_LIST = 'subscriptionList',
  PAGE_COUNT = 'pageCount',
  TOTAL_SUBSCRIPTION = 'totalSubscription',
}

export interface ISubscriptionStoreState {
  [ESubscriptionStoreAttribute.SUBSCRIPTION_LIST] : ISubscription[];
  [ESubscriptionStoreAttribute.PAGE_COUNT]: number;
  [ESubscriptionStoreAttribute.TOTAL_SUBSCRIPTION]: number;
}

export const subscriptionStore = {
  state: {
    [ESubscriptionStoreAttribute.SUBSCRIPTION_LIST]: [],
    [ESubscriptionStoreAttribute.PAGE_COUNT]: 0,
    [ESubscriptionStoreAttribute.TOTAL_SUBSCRIPTION]: 0,
  },
  mutations: {
    [ESubscriptionStoreAttribute.SUBSCRIPTION_LIST]: (
      state: ISubscriptionStoreState,
      subscriptionList: ISubscription[],
    ) => {
      state[ESubscriptionStoreAttribute.SUBSCRIPTION_LIST] = subscriptionList;
    },
    [ESubscriptionStoreAttribute.PAGE_COUNT]: (
      state: ISubscriptionStoreState,
      pageCount: number,
    ) => {
      state[ESubscriptionStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [ESubscriptionStoreAttribute.TOTAL_SUBSCRIPTION]: (
      state: ISubscriptionStoreState,
      totalSubscription: number,
    ) => {
      state[ESubscriptionStoreAttribute.TOTAL_SUBSCRIPTION] = totalSubscription;
    },
  },
  actions: {
    async getSubscriptionList(
      context: IContext,
      args: IGenericListQuery,
    ): Promise<void> {
      const apiResponse: ISubscriptionListResponseDto = await SubscriptionApiService
        .getSubscriptionList(args);
      context.commit(
        'subscriptionList',
        apiResponse.subscriptionList,
      );
      context.commit('pageCount', apiResponse.pageCount);
      context.commit('totalSubscription', apiResponse.totalCount);
    },

    async deleteSubscription(
      context: IContext,
      data: {subscriptionUUID: string},
    ): Promise<void> {
      return await SubscriptionApiService.deleteSubscription(data.subscriptionUUID);
    },
  },
};
