
import Vue from 'vue';
import {I18nApiService} from '@/service/api/i18n.api.service';

export default Vue.extend({
  data: () => ({
    synchronized: false,
  }),
  methods: {
    async synchronizeLocize() {
      this.synchronized = true;
      await I18nApiService.refreshI18n();
    },

    closeSynchronizedAlert() {
      this.synchronized = false;
    },
  },
});
