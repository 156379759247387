import {IDevice} from '@/interface/device.interface';
import {IContext} from '@/store/interface/context.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {DeviceApiService} from '@/service/api/device/device.api.service';

export enum EDeviceStoreAttribute {
  DEVICE_LIST = 'deviceList',
  PAGE_COUNT = 'pageCount',
  TOTAL_DEVICE = 'totalDevice',
}

export interface IDeviceStoreState {
  [EDeviceStoreAttribute.DEVICE_LIST]: IDevice[];
  [EDeviceStoreAttribute.PAGE_COUNT]: number;
  [EDeviceStoreAttribute.TOTAL_DEVICE]: number;
}

export const deviceStore = {
  state: {
    [EDeviceStoreAttribute.DEVICE_LIST]: [],
    [EDeviceStoreAttribute.PAGE_COUNT]: 0,
    [EDeviceStoreAttribute.TOTAL_DEVICE]: 0,
  },
  mutations: {
    [EDeviceStoreAttribute.DEVICE_LIST]: (
      state: IDeviceStoreState,
      deviceList: IDevice[],
    ) => {
      state[EDeviceStoreAttribute.DEVICE_LIST] = deviceList;
    },
    [EDeviceStoreAttribute.PAGE_COUNT]: (
      state: IDeviceStoreState,
      pageCount: number,
    ) => {
      state[EDeviceStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EDeviceStoreAttribute.TOTAL_DEVICE]: (
      state: IDeviceStoreState,
      totalDevice: number,
    ) => {
      state[EDeviceStoreAttribute.TOTAL_DEVICE] = totalDevice;
    },
  },

  actions: {
    async getDeviceList(context: IContext, args: IGenericListQuery): Promise<void> {
      const apiResponse = await DeviceApiService.getDeviceList(args);
      context.commit('deviceList', apiResponse.deviceList);
      context.commit('pageCount', apiResponse.pageCount);
      context.commit('totalDevice', apiResponse.totalCount);
    },
  },
};
