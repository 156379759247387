import {ELanguage} from '@mr-arthur/ma-api/package-registry/dist/src/Enum';
import {
  ITransferableInternalUser,
} from '@mr-arthur/ma-api/package-registry/dist/src/Service/Global/User/user.global.interface';
import {ApiService} from '../api.service';

const LOGOUT_URI = '/api/auth/logout';
const REQUEST_VALIDATION_CODE_URI = '/api/internal-user/login-email';
const VALIDATE_CODE_URI = '/api/internal-user/validate-code';
const GET_ME_URI = '/api/user/me';

export class CurrentUserApiService {
  public static async getMe(): Promise<ITransferableInternalUser> {
    const res: ITransferableInternalUser = await ApiService.get(GET_ME_URI);
    return res;
  }

  public static async validateCode(args: {
    email: string;
    code: string;
  }): Promise<ITransferableInternalUser> {
    const user: {user: ITransferableInternalUser} = await ApiService.post(VALIDATE_CODE_URI, {
      email: args.email,
      code: args.code,
    });
    return user.user;
  }

  public static async requestValidationCode(email: string): Promise<any> {
    return await ApiService.post(REQUEST_VALIDATION_CODE_URI, {
      email,
      language: ELanguage.EN,
    });
  }

  public static async logout(): Promise<any> {
    return await ApiService.post(LOGOUT_URI);
  }
}
