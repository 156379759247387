import { render, staticRenderFns } from "./ghost-view.vue?vue&type=template&id=0aa2ecc7&lang=pug&"
import script from "./ghost-view.vue?vue&type=script&lang=ts&"
export * from "./ghost-view.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports