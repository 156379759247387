import {INSnapshotRecap} from '@/interface/n.snapshot.recap.interface';
import {IContext} from '@/store/interface/context.interface';
import {ScreenTimeApiService} from '@/service/api/screenTime/screen.time.api.service';
import {
  IGetNSnapshotPerDayQuery,
} from '@/service/api/screenTime/query/get.n.snapshot.per.day.query';
import {ISubscriptionWithSnapshot} from '@/interface/subscription.with.snpashot.interface';
import {
  IGetSubscriptionWithSnapshotResponseDto,
} from '@/service/api/screenTime/dto/get.subscription.with.snapshot.list.response.dto';
import {TNSnapshotRecapPerDay} from '@/type/n.snapshot.per.day.type';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';

export enum EScreenTimeStoreAttribute {
  N_SNAPSHOT_RECAP = 'nSnapshotRecap',
  N_SNAPSHOT_PER_DAY = 'nSnapshotPerDay',
  SUBSCRIPTION_LIST = 'subscriptionList',
  PAGE_COUNT = 'pageCount',
  TOTAL_SUBSCRIPTION = 'totalSubscription',
}

export interface IScreenTimeStoreState {
  [EScreenTimeStoreAttribute.N_SNAPSHOT_RECAP]: INSnapshotRecap,
  [EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY]: TNSnapshotRecapPerDay,
  [EScreenTimeStoreAttribute.SUBSCRIPTION_LIST] : ISubscriptionWithSnapshot[];
  [EScreenTimeStoreAttribute.PAGE_COUNT]: number;
  [EScreenTimeStoreAttribute.TOTAL_SUBSCRIPTION]: number;
}

export const screenTimeStore = {
  state: {
    [EScreenTimeStoreAttribute.N_SNAPSHOT_RECAP]: {} as INSnapshotRecap,
    [EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY]: [],
    [EScreenTimeStoreAttribute.SUBSCRIPTION_LIST]: [],
    [EScreenTimeStoreAttribute.PAGE_COUNT]: 0,
    [EScreenTimeStoreAttribute.TOTAL_SUBSCRIPTION]: 0,
  },
  mutations: {
    [EScreenTimeStoreAttribute.N_SNAPSHOT_RECAP]: (
      state: IScreenTimeStoreState,
      nSnapshotRecap: INSnapshotRecap,
    ) => {
      state[EScreenTimeStoreAttribute.N_SNAPSHOT_RECAP] = nSnapshotRecap;
    },
    [EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY]: (
      state: IScreenTimeStoreState,
      nSnapshotPerDay: TNSnapshotRecapPerDay,
    ) => {
      state[EScreenTimeStoreAttribute.N_SNAPSHOT_PER_DAY] = nSnapshotPerDay;
    },
    [EScreenTimeStoreAttribute.SUBSCRIPTION_LIST]: (
      state: IScreenTimeStoreState,
      subscriptionList: ISubscriptionWithSnapshot[],
    ) => {
      state[EScreenTimeStoreAttribute.SUBSCRIPTION_LIST] = subscriptionList;
    },
    [EScreenTimeStoreAttribute.PAGE_COUNT]: (
      state: IScreenTimeStoreState,
      pageCount: number,
    ) => {
      state[EScreenTimeStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [EScreenTimeStoreAttribute.TOTAL_SUBSCRIPTION]: (
      state: IScreenTimeStoreState,
      totalSubscription: number,
    ) => {
      state[EScreenTimeStoreAttribute.TOTAL_SUBSCRIPTION] = totalSubscription;
    },
  },
  actions: {
    async getSnapshotRecap(context: IContext): Promise<void> {
      const apiResponse = await ScreenTimeApiService.getNSnapshotRecap();
      context.commit('nSnapshotRecap', apiResponse);
    },

    async getNSnapshotPerDay(
      context: IContext,
      query: IGetNSnapshotPerDayQuery,
    ): Promise<void> {
      const apiResponse = await ScreenTimeApiService.getNSnapshotPerDay(query);
      context.commit('nSnapshotPerDay', apiResponse.snapshotRecapPerDay);
    },

    async getSubscriptionWithSnapshotList(
      context: IContext,
      query: IGenericListQuery,
    ): Promise<void> {
      const apiResponse: IGetSubscriptionWithSnapshotResponseDto = await ScreenTimeApiService
        .getSubscriptionsWithSnapshot(query);
      context.commit(
        'subscriptionList',
        apiResponse.subscriptionList,
      );
      context.commit('pageCount', apiResponse.pageCount);
      context.commit('totalSubscription', apiResponse.totalCount);
    },
  },
};
