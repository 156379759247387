
import Vue from 'vue';
import {countryList} from '@/enum/country.enum';
import {activeStateList} from '@/enum/active.enum';
import {VForm} from '@/type/vform.type';
import {
  recommendedIosAppRuleListJson,
} from '@/components/recommended-ios-app/recommended.ios.app.rule.list.json';
import {deviceTypeList} from '@/enum/device.enum';
import {IAppCategory} from '@/interface/category.interface';
import {appCategoryStore, EAppCategoryStoreAttribute} from '@/store/app.category.store';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      activeStateList,
      countryList,
      recommendedIosAppRuleListJson,
      deviceTypeList,
      valid: true,
    };
  },
  async mounted() {
    await this.$store.dispatch('getAppCategoryList');
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    mobileApplicationCategoryList(): IAppCategory[] {
      const appCategoryList: IAppCategory[] = appCategoryStore
        .state[EAppCategoryStoreAttribute.APP_CATEGORY_LIST];
      return appCategoryList;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      if (this.form.validate()) {
        this.$emit('save');
      }
    },
  },
});
