
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: String,
    answers: Object,
  },
  data() {
    return {};
  },
});
