import {ApiService} from '@/service/api/api.service';
import {IMediaCategory} from '@/interface/category.interface';

const GET_MEDIA_CATEGORY_LIST_URI = '/api/media-category';
const GET_MEDIA_LIST_RELATED_TO_MEDIA_CATEGORY_URI = (
  mediaCategoryUUID: string,
) => `/api/media-category/${mediaCategoryUUID}/youtube-media`;
const CREATE_MEDIA_CATEGORY = '/api/media-category/new';
const UPDATE_MEDIA_CATEGORY_URI = (
  mediaCategoryUUID: string,
) => `/api/media-category/${mediaCategoryUUID}`;
const DELETE_MEDIA_CATEGORY_URI = (
  mediaCategoryUUID: string,
) => `/api/media-category/${mediaCategoryUUID}`;

export class MediaCategoryApiService {
  public static async getMediaCategoryList(): Promise<IMediaCategory[]> {
    return await ApiService.get(GET_MEDIA_CATEGORY_LIST_URI);
  }

  public static async getMediaListRelatedToMediaCategory(
    mediaCategoryUUID: string,
  ): Promise<IMediaCategory[]> {
    return await ApiService.get(GET_MEDIA_LIST_RELATED_TO_MEDIA_CATEGORY_URI(mediaCategoryUUID));
  }

  public static async createMediaCategory(args: Partial<IMediaCategory>): Promise<IMediaCategory> {
    return await ApiService.post(CREATE_MEDIA_CATEGORY, args);
  }

  public static async updateMediaCategory(
    mediaCategoryUUID: string,
    args: Partial<IMediaCategory>,
  ): Promise<IMediaCategory> {
    return await ApiService.post(UPDATE_MEDIA_CATEGORY_URI(mediaCategoryUUID), args);
  }

  public static async deleteMediaCategory(mediaCategoryUUID: string): Promise<void> {
    return await ApiService.delete(DELETE_MEDIA_CATEGORY_URI(mediaCategoryUUID));
  }
}
