import {EDeviceType} from '@/enum/device.enum';
import {ECountry} from '@/enum/country.enum';
import {EMobileApplicationCategory} from '@/enum/mobile.application.enum';
import {isDef, isUndef} from '@/service/static/control.service';

export const recommendedIosAppRuleListJson = {
  nameRules: [
    (v: string) => Boolean(v) || 'Name is required',
    (v: string) => (v && v.length <= 255) || 'Name must be less than 256 characters',
  ],
  commentRules: [
    (v: string) => Boolean(v) || 'Comment is required',
    (v: string) => (v && v.length <= 1023) || 'Comment must be less than 1024 characters',
  ],
  deviceTypeListRules: [
    (v: EDeviceType[]) => v.length > 0 || 'Device type is required',
  ],
  countryListRules: [
    (v: ECountry[]) => v.length > 0 || 'Country is required',
  ],
  categoryRules: [
    (v: EMobileApplicationCategory) => Boolean(v) || 'Category is required',
  ],
  linkRules: [
    (v: string) => Boolean(v) || 'Link is required',
    (v: string) => (v && v.length <= 255) || 'Link must be less than 256 characters',
  ],
  urlSchemeRules: [
    // (v: string) => Boolean(v) || ' is required',
    (v: string) => {
      // console.log('Value: ', typeof v, v.length, (v && v.length <= 255) === true, v.length <= 255, typeof v !== 'undefined');
      return v === null || (typeof v !== 'undefined' && v.length <= 255) || 'URL SCHEME must be less than 256 characters';
    },
  ],
};
