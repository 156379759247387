import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {I18nApiService} from './service/api/i18n.api.service';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.use(VueI18n);

const loadVue = async () => {
  const {vueI18n, i18nLanguageList} = await I18nApiService.getI18n();
  (store as any).state.i18nLanguageList = i18nLanguageList;
  new Vue({
    router,
    store,
    i18n: vueI18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
};

loadVue();
