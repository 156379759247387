
import {EUserRole} from '@/enum/user.enum';
import {currentUserStore} from '@/store/user/current.user.store';

interface ISublink {
  to: string;
  icon: string;
  text: string;
}

interface ILink {
  icon: string;
  text: string;
  to?: string;

  subLinks?: ISublink[];
  superAdminOnly?: boolean;
}

export default {
  name: 'NavigationBarLeft',
  data: () => ({
    links: [
      {
        to: '/',
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
      },
      {
        icon: 'mdi-math-compass',
        text: 'Stats',
        subLinks: [
          {
            text: 'Onboarding stats',
            to: '/stats/onboarding',
            icon: 'mdi-stairs',
          },
        ],
      },
      {
        icon: 'mdi-timer-sand',
        text: 'Screen time',
        subLinks: [
          {
            text: 'By subscription',
            to: '/screen-time/by-subscription',
            icon: 'mdi-wallet',
          },
        ],
      },
      {
        icon: 'mdi-multimedia',
        text: 'Media',
        subLinks: [
          {
            text: 'Videos',
            to: '/media/video',
            icon: 'mdi-movie-open-play',
          },
          {
            text: 'Categories',
            to: '/media/category',
            icon: 'mdi-shape',
          },
        ],
      },
      {
        icon: 'mdi-apps',
        text: 'Applications',
        subLinks: [
          {
            text: 'IOS',
            to: '/recommended-app/ios',
            icon: 'mdi-apple',
          },
          {
            text: 'Android',
            to: '/recommended-app/android',
            icon: 'mdi-android',
          },
          {
            text: 'Categories',
            to: '/recommended-app/category',
            icon: 'mdi-shape',
          },
        ],
      },
      {
        icon: 'mdi-account-box-multiple',
        text: 'Users',
        subLinks: [
          {
            text: 'Observer users',
            to: '/user/observer-user',
            icon: 'mdi-account-eye',
          },
          {
            text: 'Attendee users',
            to: '/user/attendee-user',
            icon: 'mdi-account-lock-open',
          },
          {
            text: 'Internal users',
            to: '/user/internal-user',
            icon: 'mdi-account-hard-hat',
          },
        ],
      },
      {
        icon: 'mdi-wallet',
        text: 'Subscriptions',
        subLinks: [
          {
            text: 'Subscriptions',
            to: '/subscription/subscription',
            icon: 'mdi-wallet',
          },
        ],
      },
      {
        icon: 'mdi-tablet-cellphone',
        text: 'Devices',
        subLinks: [
          {
            text: 'Devices',
            to: '/device/device',
            icon: 'mdi-tablet-cellphone',
          },
        ],
      },
      {
        superAdminOnly: true,
        icon: 'mdi-key',
        text: 'Super-admin',
        subLinks: [
          {
            text: 'Internationalization',
            to: '/super-admin/internationalization',
            icon: 'mdi-translate',
          },
          {
            text: 'Ghosts',
            to: '/super-admin/ghost',
            icon: 'mdi-ghost',
          },
          {
            text: 'App parameters',
            to: '/super-admin/admin-app-parameters',
            icon: 'mdi-tune-vertical',
          },
          {
            text: 'Dev Hot !!',
            to: '/super-admin/admin-dev',
            icon: 'mdi-chili-hot-outline',
          },
          {
            text: 'CSV',
            to: '/super-admin/admin-csv',
            icon: 'mdi-view-list-outline',
          },
        ],
      },
    ],
  }),

  methods: {
    userHasRights(link: ILink) {
      return currentUserStore.state.user.role === EUserRole.INTERNAL_USER_SUPER_ADMIN
        || !link?.superAdminOnly;
    },
  },
};
