import {IAdminAppParameters} from '@/interface/admin.app.parameters.interface';
import {IContext} from '@/store/interface/context.interface';
import {
  AdminAppParametersApiService,
} from '@/service/api/adminAppParameters/admin.app.parameters.api.service';
import {
  IUpdateAdminAppParametersDto,
} from '@/service/api/adminAppParameters/dto/update.admin.app.parameters.dto';

export enum EAdminAppParametersStoreAttribute {
  ADMIN_APP_PARAMETERS = 'adminAppParameters',
}

export interface IAdminAppParametersStoreState {
  [EAdminAppParametersStoreAttribute.ADMIN_APP_PARAMETERS]: IAdminAppParameters;
}

export const adminAppParametersStore = {
  state: {
    [EAdminAppParametersStoreAttribute.ADMIN_APP_PARAMETERS]: {} as IAdminAppParameters,
  },
  getters: {},
  mutations: {
    [EAdminAppParametersStoreAttribute.ADMIN_APP_PARAMETERS]: (
      state: IAdminAppParametersStoreState,
      adminAppParameters: IAdminAppParameters,
    ) => {
      state[EAdminAppParametersStoreAttribute.ADMIN_APP_PARAMETERS] = adminAppParameters;
    },
  },
  actions: {
    async getAdminAppParameters(context: IContext): Promise<void> {
      const apiResponse = await AdminAppParametersApiService.getAdminAppParameters();
      context.commit('adminAppParameters', apiResponse);
    },

    async updateAdminAppParameters(
      context: IContext,
      data: {adminAppParametersData: IUpdateAdminAppParametersDto},
    ): Promise<IAdminAppParameters> {
      return await AdminAppParametersApiService
        .updateAdminAppParameters(data.adminAppParametersData);
    },
  },
};
