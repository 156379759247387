
import Vue from 'vue';

import {VForm} from '@/type/vform.type';
import {appCategoryStore, EAppCategoryStoreAttribute} from '@/store/app.category.store';
import {IAppCategory} from '@/interface/category.interface';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
    appCategoryRuleListJson() {
      const existingFrNames = appCategoryStore.state[EAppCategoryStoreAttribute.APP_CATEGORY_LIST]
        .map((cat: IAppCategory) => cat.frName);
      const existingEnNames = appCategoryStore.state[EAppCategoryStoreAttribute.APP_CATEGORY_LIST]
        .map((cat: IAppCategory) => cat.enName);
      return {
        frNameRules: [
          (v: string) => Boolean(v) || 'French name is required',
          (v: string) => (v && v.length <= 255) || 'French name must be less than 256 characters',
          (v: string) => (!existingFrNames.includes(v)) || 'French name already exists',
        ],
        enNameRules: [
          (v: string) => Boolean(v) || 'English name is required',
          (v: string) => (v && v.length <= 255) || 'English name must be less than 256 characters',
          (v: string) => (!existingEnNames.includes(v)) || 'English name already exists',
        ],
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      if (this.form.validate()) {
        this.$emit('save');
      }
    },
  },
});
