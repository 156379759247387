import {IRecommendedApp} from '@/interface/recommended.app.interface';
import {RecommendedAppApiService} from '@/service/api/recommendedApp/recommended.app.api.service';
import {ICreateRecommendedAppDto} from '@/service/api/recommendedApp/dto/create.recommended.app.dto';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {IContext} from '@/store/interface/context.interface';
import {IRecommendedAppResponseDto} from '@/service/api/recommendedApp/dto/recommended.app.response.dto';
import {EDevicePlatform} from '@/enum/device.enum';

export enum ERecommendedIOSAppStoreAttribute {
  RECOMMENDED_IOS_APP_LIST = 'recommendedIOSAppList',
  FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST = 'fullActiveRecommendedIOSAppList',
  PAGE_COUNT = 'pageCount',
  TOTAL_RECOMMENDED_IOS_APP = 'totalRecommendedIOSApp',
}

export interface IRecommendedAppStoreState {
  [ERecommendedIOSAppStoreAttribute.RECOMMENDED_IOS_APP_LIST]: IRecommendedApp[];
  [ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST]: IRecommendedApp[];
  [ERecommendedIOSAppStoreAttribute.PAGE_COUNT]: number;
  [ERecommendedIOSAppStoreAttribute.TOTAL_RECOMMENDED_IOS_APP]: number;
}

export const recommendedIosAppStore = {
  state: {
    [ERecommendedIOSAppStoreAttribute.RECOMMENDED_IOS_APP_LIST]: [],
    [ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST]: [],
    [ERecommendedIOSAppStoreAttribute.PAGE_COUNT]: 0,
    [ERecommendedIOSAppStoreAttribute.TOTAL_RECOMMENDED_IOS_APP]: 0,
  },
  getters: {},
  mutations: {
    [ERecommendedIOSAppStoreAttribute.RECOMMENDED_IOS_APP_LIST]: (
      state: IRecommendedAppStoreState,
      recommendedIOSAppList: IRecommendedApp[],
    ) => {
      state[ERecommendedIOSAppStoreAttribute.RECOMMENDED_IOS_APP_LIST] = recommendedIOSAppList;
    },
    [ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST]: (
      state: IRecommendedAppStoreState,
      recommendedIOSAppList: IRecommendedApp[],
    ) => {
      state[
        ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST
      ] = recommendedIOSAppList;
    },
    [ERecommendedIOSAppStoreAttribute.PAGE_COUNT]: (
      state: IRecommendedAppStoreState,
      pageCount: number,
    ) => {
      state[ERecommendedIOSAppStoreAttribute.PAGE_COUNT] = pageCount;
    },
    [ERecommendedIOSAppStoreAttribute.TOTAL_RECOMMENDED_IOS_APP]: (
      state: IRecommendedAppStoreState,
      totalRecommendedIOSApp: number,
    ) => {
      state[ERecommendedIOSAppStoreAttribute.TOTAL_RECOMMENDED_IOS_APP] = totalRecommendedIOSApp;
    },
  },
  actions: {
    async getRecommendedIOSAppListFromApi(
      context: IContext,
      args: IGenericListQuery,
    ): Promise<void> {
      const apiResponse: IRecommendedAppResponseDto = await RecommendedAppApiService
        .getRecommendedAppListFromApi(args, EDevicePlatform.IOS);
      context.commit(
        'recommendedIOSAppList',
        apiResponse.recommendedAppList,
      );
      context.commit('pageCount', apiResponse.pageCount);
      context.commit('totalRecommendedIOSApp', apiResponse.totalCount);
    },

    async getFullActiveRecommendedIOSAppListFromApi(
      context: IContext,
    ): Promise<void> {
      const apiResponse: IRecommendedApp[] = await RecommendedAppApiService
        .getFullActiveRecommendedAppList(EDevicePlatform.IOS);
      context.commit(
        ERecommendedIOSAppStoreAttribute.FULL_ACTIVE_RECOMMENDED_IOS_APP_LIST,
        apiResponse,
      );
    },

    async updateFullActiveRecommendedIOSAppList(
      context: IContext,
      data: {recommendedIOSAppList: IRecommendedApp[]},
    ): Promise<void> {
      await RecommendedAppApiService.updateFullActiveRecommendedAppList(
        data.recommendedIOSAppList,
        EDevicePlatform.IOS,
      );
    },

    // async createRecommendedIOSApp(
    //   context: IContext,
    //   data: {recommendedApp: ICreateRecommendedAppDto},
    // ): Promise<void> {
    //   await RecommendedAppApiService.newRecommendedApp(data.recommendedApp, EDevicePlatform.IOS);
    // },
    //
    // async updateRecommendedIOSApp(
    //   context: IContext,
    //   data: {recommendedIOSAppUUID: string, recommendedIOSApp: Partial<IRecommendedApp>},
    // ): Promise<IRecommendedApp> {
    //   return await RecommendedAppApiService.updateRecommendedApp(
    //     data.recommendedIOSAppUUID,
    //     data.recommendedIOSApp,
    //   );
    // },
    //
    // async deleteRecommendedIOSApp(
    //   context: IContext,
    //   data: {recommendedAppUUID: string},
    // ): Promise<void> {
    //   return await RecommendedAppApiService.deleteRecommendedApp(data.recommendedAppUUID);
    // },
  },
};
