import { render, staticRenderFns } from "./with-snapshot-card.vue?vue&type=template&id=3e6b5f78&scoped=true&lang=pug&"
import script from "./with-snapshot-card.vue?vue&type=script&lang=ts&"
export * from "./with-snapshot-card.vue?vue&type=script&lang=ts&"
import style0 from "./with-snapshot-card.vue?vue&type=style&index=0&id=3e6b5f78&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6b5f78",
  null
  
)

export default component.exports