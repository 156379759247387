
import Vue from 'vue';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {EScreenTimeStoreAttribute, screenTimeStore} from '@/store/screen.time.store';
import {
  IAttendeeUserWithSnapshot,
  ISubscriptionWithSnapshot,
} from '@/interface/subscription.with.snpashot.interface';
import {footerProps} from '@/views/table.const';
import {formatDateTime} from '@/filters/format.date.time.filter';

export default Vue.extend({
  data: () => ({
    footerProps,
    isLoading: false,
    search: '',
    subscriptionPaginationOptions: {} as IPaginationOptions,
    showSnapshotPopup: false,
    subscriptionExpanded: [],
    snapshotExpanded: [],
    clickedAttendeeUser: {} as IAttendeeUserWithSnapshot,
    withEmailOnly: true,
    deviceDetailsHeaders: [
      {
        text: 'Model',
        value: 'deviceModel',
      },
      {
        text: 'Entertainment time',
        value: 'entertainmentTimeSpent',
      },
      {
        text: 'Educational time',
        value: 'educationalTimeSpent',
      },
      {
        text: 'Total time',
        value: 'allCategoriesTimeSpent',
      },
    ],
    subscriptionHeaders: [
      {
        text: 'Referent observer email',
        value: 'referentObserverUserEmail',
        sortable: false,
      },
      {
        text: 'Number of snapshots',
        value: 'nSnapshots',
        sortable: false,
      },
      {
        text: 'Number of attendees',
        value: 'nAttendees',
        sortable: false,
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false,
      },
    ],
    attendeeHeaders: [
      {
        text: 'name',
        value: 'name',
      },
      {
        text: 'Number of snapshots',
        value: 'nSnapshots',
      },
      {
        text: 'Total entertainment time',
        value: 'totalEntertainmentTime',
      },
      {
        text: 'Total educational time',
        value: 'totalEducationalTime',
      },
      {
        text: 'Total time',
        value: 'totalAllCategoriesTime',
      },
    ],
    snapshotHeaders: [
      {
        text: 'Date',
        value: 'date',
      },
      {
        text: 'Total entertainment time',
        value: 'totalEntertainmentTime',
      },
      {
        text: 'Total educational time',
        value: 'totalEducationalTime',
      },
      {
        text: 'Total time',
        value: 'totalAllCategoriesTime',
      },
    ],
  }),
  filters: {formatDateTime},
  computed: {
    totalSubscription(): number {
      return screenTimeStore.state[EScreenTimeStoreAttribute.TOTAL_SUBSCRIPTION];
    },
    subscriptionList(): ISubscriptionWithSnapshot[] {
      const subscriptionList: ISubscriptionWithSnapshot[] = screenTimeStore
        .state[EScreenTimeStoreAttribute.SUBSCRIPTION_LIST];
      return subscriptionList;
    },
  },
  async mounted() {
    await this.getDataFromApi();
  },
  watch: {
    async subscriptionPaginationOptions() {
      await this.getDataFromApi();
    },
  },
  methods: {
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
        'getSubscriptionWithSnapshotList',
        {
          pageNumber: this.subscriptionPaginationOptions.page - 1 ?? 0,
          pageSize: this.subscriptionPaginationOptions.itemsPerPage ?? 10,
          search: this.search,
          withEmailOnly: this.withEmailOnly,
        },
      );
      this.isLoading = false;
    },
    openSnapshotPopup(value: any) {
      this.clickedAttendeeUser = value;
      this.showSnapshotPopup = true;
    },
  },
});
