
import Vue from 'vue';
import {VForm} from '@/type/vform.type';
import draggable from 'vuedraggable';
import Sortable from 'sortablejs';

export default Vue.extend({
  props: {
    show: Boolean,
    youtubeMediaList: Array,
  },
  data() {
    return {
      listToSort: [...this.youtubeMediaList],
      valid: true,
      headers: [
        {
          text: 'Move',
          value: 'move',
          sortable: false,
        },
        {
          text: 'Title',
          value: 'title',
          sortable: false,
        },
        {
          text: 'Country list',
          value: 'countryList',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'categoryList',
          sortable: false,
        },
        // todo add duration hh:mm:ss
      ],
    };
  },
  components: {
    draggable,
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    saveOrder(event) {
      const movedItem = this.listToSort.splice(event.oldIndex, 1)[0];
      this.listToSort.splice(event.newIndex, 0, movedItem);
    },
    close() {
      this.$emit('close');
    },
    async save() {
      if (this.form.validate()) {
        await this.$store.dispatch(
          'updateFullActiveYoutubeMediaList',
          {
            orderedList: this.listToSort,
          },
        );
        this.$emit('save');
      }
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: (event) => {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
});
