
import Vue from 'vue';
import {observerUserStore} from '@/store/user/observer.user.store';
import {ObserverUser} from '@mr-arthur/ma-api/package-registry/dist/src/TypeORM/MA';
import {observerUserHeaderList} from '@/views/user/observer.user.header.list';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {activeStateList} from '@/enum/active.enum';
import ActiveFilter from '@/components/active-filter.vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import {IObserverUser} from '@/interface/observer.user.interface';
import DialogCreateUpdate from '@/components/user/dialog-create-update-observer-user.vue';
import {footerProps} from '@/views/table.const';

export default Vue.extend({
  data: () => ({
    footerProps,
    isLoading: false,
    options: {} as IPaginationOptions,
    expanded: [],
    activeFilterList: [...activeStateList],
    search: '',
    withEmailOnly: true,
    showCreateUpdateDialog: false,
    editedIndex: -1,
    editedItem: {
      contact: {
        mailNotificationAllowed: true,
        email: '',
      },
    } as Partial<ObserverUser>,
    defaultItem: {
      contact: {
        mailNotificationAllowed: true,
        email: '',
      },
      uuid: '',
    },
    headers: observerUserHeaderList,
  }),
  components: {
    ActiveFilter,
    DialogCreateUpdate,
  },
  computed: {
    totalObserverUser() {
      return observerUserStore.state.totalObserverUser;
    },
    observerUserList(): IObserverUser[] {
      return observerUserStore.state.observerUserList;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New observer user' : 'Edit observer user';
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  filters: {
    formatDateTime,
  },
  methods: {
    openUpdateDialog(item: IObserverUser) {
      this.setEditedItem(item);
      this.openCreateUpdateDialog();
    },
    openCreateUpdateDialog() {
      this.showCreateUpdateDialog = true;
    },
    closeCreateUpdateDialog() {
      this.showCreateUpdateDialog = false;
    },
    setEditedItem(item: IObserverUser) {
      this.editedItem = {...item};
      this.editedIndex = this.observerUserList.indexOf(item);
    },
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
        'getObserverUserList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
          withEmailOnly: this.withEmailOnly,
        },
      );
      this.isLoading = false;
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          'updateObserverUser',
          {
            observerUserUUID: this.editedItem.uuid,
            observerUserData: this.editedItem.contact,
          },
        );
      }
      this.closeCreateUpdateDialog();
      await this.getDataFromApi();
    },
  },
});
