
import Vue from 'vue';
import {VForm} from '@/type/vform.type';
import {
  AdminAppParametersApiService,
} from '@/service/api/adminAppParameters/admin.app.parameters.api.service';
import {IUpdateAdminAppParametersDto} from '@/service/api/adminAppParameters/dto/update.admin.app.parameters.dto';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object as () => IUpdateAdminAppParametersDto,
  },
  data() {
    return {
      valid: true,
    };
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async save() {
      if (this.form.validate()) {
        await AdminAppParametersApiService.updateAdminAppParameters({
          hasToCollectObserverUserPhoneNumber: this.value.hasToCollectObserverUserPhoneNumber,
        });
        await this.$store.dispatch('getAdminAppParameters');
        this.$emit('close');
      }
    },
  },
});
