import {ApiService} from '@/service/api/api.service';
import {
  IGetNSnapshotPerDayQuery,
} from '@/service/api/screenTime/query/get.n.snapshot.per.day.query';
import {
  IGetNumberSnapshotPerDayResponseDto,
} from '@/service/api/screenTime/dto/get.n.snapshot.per.day.response.dto';
import {INSnapshotRecap} from '@/interface/n.snapshot.recap.interface';
import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IGetSubscriptionWithSnapshotResponseDto,
} from '@/service/api/screenTime/dto/get.subscription.with.snapshot.list.response.dto';

const GET_N_SNAPSHOT_RECAP_URI = '/api/screen-time/statistics/number-snapshot-recap';
const GET_N_SNAPSHOT_PER_DAY = '/api/screen-time/statistics/snapshot-per-day';
const GET_SUBSCRIPTION_WITH_SNAPSHOT_LIST = '/api/screen-time/subscription';

export class ScreenTimeApiService {
  public static async getNSnapshotRecap(): Promise<INSnapshotRecap> {
    const apiResponse = await ApiService.get(GET_N_SNAPSHOT_RECAP_URI);
    return apiResponse;
  }

  public static async getNSnapshotPerDay(
    query: IGetNSnapshotPerDayQuery,
  ): Promise<IGetNumberSnapshotPerDayResponseDto> {
    const apiResponse = await ApiService.get(GET_N_SNAPSHOT_PER_DAY, query);
    return apiResponse;
  }

  public static async getSubscriptionsWithSnapshot(
    query: IGenericListQuery,
  ): Promise<IGetSubscriptionWithSnapshotResponseDto> {
    return await ApiService.get(GET_SUBSCRIPTION_WITH_SNAPSHOT_LIST, query);
  }

  public static async removeCompletedScreenTimeJobs(): Promise<void> {
    await ApiService.post('/api/admin-remove-completed-screen-time-jobs');
  }
}
