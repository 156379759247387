
import Vue from 'vue';
import {MediaCategoryApiService} from '@/service/api/media/media.category.api.service';
import {IYoutubeMedia} from '@/interface/youtube.media.interface';
import {EYoutubeMediaStoreAttribute, youtubeMediaStore} from '@/store/youtube.media.store';

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object,
  },
  data() {
    return {
      valid: true,
      mediaWithOnlyThisCategoryList: [] as IYoutubeMedia[],
      showWarning: false,
    };
  },
  async mounted() {
    await this.getFormattedMedia();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteItemConfirm() {
      this.$emit('delete');
    },
    async getFormattedMedia() {
      const relatedMobileMediaList = await MediaCategoryApiService
        .getMediaListRelatedToMediaCategory(this.$props.value.uuid);
      const relatedMobileMediaIdList = relatedMobileMediaList.map((media) => media.id);
      this.mediaWithOnlyThisCategoryList = youtubeMediaStore
        .state[EYoutubeMediaStoreAttribute.FULL_ACTIVE_YOUTUBE_MEDIA_LIST]
        .filter((media: IYoutubeMedia) => relatedMobileMediaIdList.includes(media.id)
          && media.mediaCategoryList.length === 1);
      this.showWarning = this.mediaWithOnlyThisCategoryList.length !== 0;
    },
  },
});
