import {IAdminAppParameters} from '@/interface/admin.app.parameters.interface';
import {ApiService} from '@/service/api/api.service';
import {
  IUpdateAdminAppParametersDto,
} from '@/service/api/adminAppParameters/dto/update.admin.app.parameters.dto';

const GET_ADMIN_APP_PARAMETERS = '/api/admin-app-parameters';
const UPDATE_ADMIN_APP_PARAMETERS = '/api/admin-app-parameters';

export class AdminAppParametersApiService {
  public static async getAdminAppParameters(): Promise<IAdminAppParameters> {
    return await ApiService.get(GET_ADMIN_APP_PARAMETERS);
  }

  public static async updateAdminAppParameters(
    dto: IUpdateAdminAppParametersDto,
  ): Promise<IAdminAppParameters> {
    return await ApiService.post(UPDATE_ADMIN_APP_PARAMETERS, dto);
  }
}
