
import Vue from 'vue';
import {formatDateTime} from '@/filters/format.date.time.filter';
import DialogDelete from '@/components/dialog-delete.vue';
import ActiveFilter from '@/components/active-filter.vue';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {IInternalUser} from '@/interface/internal.user.interface';
import {internalUserHeaderList} from '@/views/user/internal.user.header.list';
import DialogCreateUpdate from '@/components/user/dialog-create-update-internal-user.vue';
import {EInternalUserStoreAttribute, internalUserStore} from '@/store/user/internal.user.store';
import {footerProps} from '@/views/table.const';

export default Vue.extend({
  data: () => ({
    footerProps,
    isLoading: false,
    options: {} as IPaginationOptions,
    search: '',
    showOrderDialog: false,
    showDeleteDialog: false,
    showCreateUpdateDialog: false,
    editedIndex: -1,
    editedItem: {
    } as Partial<IInternalUser>,
    defaultItem: {
      role: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    headers: internalUserHeaderList,
  }),
  components: {
    ActiveFilter,
    DialogDelete,
    DialogCreateUpdate,
  },
  computed: {
    totalInternalUser() {
      return internalUserStore.state.totalInternalUser;
    },
    internalUserList() {
      return internalUserStore.state[
        EInternalUserStoreAttribute.INTERNAL_USER_LIST
      ] as IInternalUser[];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New internal user' : 'Edit internal user';
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  filters: {
    formatDateTime,
  },
  methods: {
    openCreateDialog() {
      this.editedItem = {...this.defaultItem};
      this.editedIndex = -1;
      this.openCreateUpdateDialog();
    },
    openUpdateDialog(item: IInternalUser) {
      this.setEditedItem(item);
      this.openCreateUpdateDialog();
    },
    openCreateUpdateDialog() {
      this.showCreateUpdateDialog = true;
    },
    closeCreateUpdateDialog() {
      this.showCreateUpdateDialog = false;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    openDeleteDialog(item: IInternalUser) {
      this.setEditedItem(item);
      this.showDeleteDialog = true;
    },
    setEditedItem(item: IInternalUser) {
      this.editedItem = {...item};
      this.editedIndex = this.internalUserList.indexOf(item);
    },
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
        'getInternalUserList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
        },
      );
      this.isLoading = false;
    },

    async deleteItemConfirm() {
      await this.$store.dispatch(
        'deleteInternalUser',
        {internalUserUUID: this.editedItem.uuid},
      );
      await this.getDataFromApi();
      this.closeDeleteDialog();
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.$store.dispatch(
          'updateInternalUser',
          {internalUserUUID: this.editedItem.uuid, internalUserData: this.editedItem},
        );
      } else {
        await this.$store.dispatch(
          'createInternalUser',
          {internalUserData: this.editedItem},
        );
      }
      this.closeCreateUpdateDialog();
      await this.getDataFromApi();
    },
  },
});
