import {IAppCategory} from '@/interface/category.interface';
import {ApiService} from '@/service/api/api.service';
import {IRecommendedApp} from '@/interface/recommended.app.interface';

const GET_APP_CATEGORY_LIST_URI = '/api/app-category';
const GET_APP_LIST_RELATED_TO_APP_CATEGORY_URI = (
  appCategoryUUID: string,
) => `/api/app-category/${appCategoryUUID}/recommended-mobile-application`;
const CREATE_APP_CATEGORY = '/api/app-category/new';
const UPDATE_APP_CATEGORY_URI = (
  appCategoryUUID: string,
) => `/api/app-category/${appCategoryUUID}`;
const DELETE_APP_CATEGORY_URI = (
  appCategoryUUID: string,
) => `/api/app-category/${appCategoryUUID}`;

export class AppCategoryApiService {
  public static async getAppCategoryList(): Promise<IAppCategory[]> {
    return await ApiService.get(GET_APP_CATEGORY_LIST_URI);
  }

  public static async getAppListRelatedToAppCategory(
    appCategoryUUID: string,
  ): Promise<IRecommendedApp[]> {
    return await ApiService.get(GET_APP_LIST_RELATED_TO_APP_CATEGORY_URI(appCategoryUUID));
  }

  public static async createAppCategory(args: Partial<IAppCategory>): Promise<IAppCategory> {
    return await ApiService.post(CREATE_APP_CATEGORY, args);
  }

  public static async updateAppCategory(
    appCategoryUUID: string,
    args: Partial<IAppCategory>,
  ): Promise<IAppCategory> {
    return await ApiService.post(UPDATE_APP_CATEGORY_URI(appCategoryUUID), args);
  }

  public static async deleteAppCategory(appCategoryUUID: string): Promise<void> {
    return await ApiService.delete(DELETE_APP_CATEGORY_URI(appCategoryUUID));
  }
}
