export enum EDevicePlatform {
  IOS = 'IOS',
  ANDROID = 'ANDROID'
}

export enum EDeviceType {
  SMART_PHONE = 'SMART_PHONE',
  TABLET = 'TABLET'
}

export const deviceTypeList = Object.values(EDeviceType);
