import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {ApiService} from '@/service/api/api.service';
import {
  IAttendeeUserListResponseDto,
} from '@/service/api/user/attendeeUser/dto/attendee.user.list.response.dto';
import {IAttendeeUserUpdateDto} from '@/service/api/user/attendeeUser/dto/attendee.user.update.dto';
import {
  IUpdateAttendeeUserMediationDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.mediation.dto';
import {IAttendeeUserMediation} from '@/interface/mediation.interface';
import {
  IAcceptAttendeeUserMediationStepDto,
} from '@/service/api/user/attendeeUser/dto/accept.attendee.user.mediation.step.dto';
import {
  IUpdateAttendeeUserPresetDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.preset.dto';
import {IAttendeeUser} from '@/interface/attendee.user.interface';
import {
  IUpdateAttendeeUserRestPeriodDto,
} from '@/service/api/user/attendeeUser/dto/update.attendee.user.rest.period.dto';
import {
  AttendeeUserOnboardingResponseStatsDto,
} from '@/service/api/user/attendeeUser/dto/attendee.user.onboarding.response.stats.dto';
import {
  GetAdminAttendeeCsvResponseDto, GetAdminAttendeeScreenTimeConsumptionCsvResponseDto,
  GetAdminOldMediationCsvResponseDto,
} from '@/service/api/user/attendeeUser/dto/attendee.user.csv.dto';

const GET_ATTENDEE_USER_LIST_URI = '/api/admin/attendee-user';
const UPDATE_ATTENDEE_USER_URI = (
  attendeeUserUUID: string,
) => `/api/attendee-user/${attendeeUserUUID}`;

const DELETE_ATTENDEE_USER_URI = (
  attendeeUserUUID: string,
) => `/api/attendee-user/${attendeeUserUUID}`;

const UPDATE_MEDIATION_URI = (
  mediationUUID: string,
) => `/api/attendee-user-mediation/${mediationUUID}`;

const ACCEPT_LATEST_MEDIATION_STEP_URI = (
  mediationUUID: string,
) => `/api/attendee-user-mediation/${mediationUUID}/accept-latest-step`;

const UPDATE_REST_PERIOD_URI = (
  attendeeUserUUID: string,
) => `/api/attendee-user/${attendeeUserUUID}/parental-control-settings/rest-period`;

const UPDATE_PRESET_URI = (
  attendeeUserUUID: string,
) => `/api/attendee-user/${attendeeUserUUID}/parental-control-settings/preset`;

const GET_ADMIN_ATTENDEE_CSV: string = `/api/admin/attendee-csv`;
const GET_ADMIN_OLD_MEDIATION_CSV: string = `/api/admin/old-mediation-csv`;
const GET_ADMIN_ST_CONSUMPTION_EVOLUTION_CSV: string =
  `/api/admin/attendee-screen-time-consumption-evolution-csv`;

const GET_ATTENDEE_USER_ONBOARDING_STATS_URI: string = '/api/admin/attendee-user/onboarding/stats';

export class AttendeeUserApiService {
  public static async getAttendeeUserList(
    args: IGenericListQuery,
  ): Promise<IAttendeeUserListResponseDto> {
    return await ApiService.get(GET_ATTENDEE_USER_LIST_URI, args);
  }

  public static async updateAttendeeUser(
    attendeeUserUUID: string,
    args: IAttendeeUserUpdateDto,
  ): Promise<void> {
    return await ApiService.put(UPDATE_ATTENDEE_USER_URI(attendeeUserUUID), args);
  }

  public static async deleteAttendeeUser(
    attendeeUserUUID: string,
  ): Promise<void> {
    return await ApiService.delete(DELETE_ATTENDEE_USER_URI(attendeeUserUUID));
  }

  public static async updateAttendeeUserMediation(
    mediationUUID: string,
    args: IUpdateAttendeeUserMediationDto,
  ): Promise<IAttendeeUserMediation> {
    return ApiService.post(UPDATE_MEDIATION_URI(mediationUUID), args);
  }

  public static async acceptMediationStep(
    mediationUUID: string,
    args: IAcceptAttendeeUserMediationStepDto,
  ): Promise<IAttendeeUserMediation> {
    return ApiService.post(ACCEPT_LATEST_MEDIATION_STEP_URI(mediationUUID), args);
  }

  public static async updateRestPeriod(
    attendeeUserUUID: string,
    args: IUpdateAttendeeUserRestPeriodDto,
  ): Promise<IAttendeeUser> {
    return ApiService.post(UPDATE_REST_PERIOD_URI(attendeeUserUUID), args);
  }

  public static async updatePreset(
    attendeeUserUUID: string,
    args: IUpdateAttendeeUserPresetDto,
  ): Promise<IAttendeeUser> {
    return ApiService.post(UPDATE_PRESET_URI(attendeeUserUUID), args);
  }

  public static async getAttendeeUserOnboardingStats():
    Promise<AttendeeUserOnboardingResponseStatsDto> {
    const response = ApiService.get(GET_ATTENDEE_USER_ONBOARDING_STATS_URI);
    return response;
  }

  public static async getAttendeeUserCSV():
    Promise<GetAdminAttendeeCsvResponseDto[]> {
    const response: Promise<GetAdminAttendeeCsvResponseDto[]> = ApiService.get(GET_ADMIN_ATTENDEE_CSV);
    return response;
  }

  public static async getOldMediationCSV():
    Promise<GetAdminOldMediationCsvResponseDto[]> {
    const response: Promise<GetAdminOldMediationCsvResponseDto[]> = ApiService
      .get(GET_ADMIN_OLD_MEDIATION_CSV);
    return response;
  }

  public static async getScreenTimeConsumptionEvolutionCSV():
    Promise<GetAdminAttendeeScreenTimeConsumptionCsvResponseDto[]> {
    const response: Promise<GetAdminAttendeeScreenTimeConsumptionCsvResponseDto[]> = ApiService
      .get(GET_ADMIN_ST_CONSUMPTION_EVOLUTION_CSV);
    return response;
  }
}
