import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {ApiService} from '@/service/api/api.service';
import {
  ISubscriptionListResponseDto,
} from '@/service/api/subscription/dto/subscription.list.response.dto';
import {ISubscriptionTrial} from '@/interface/subscription.trial.interface';

const GET_SUBSCRIPTION_LIST_URI = '/api/admin/subscription';

const DELETE_SUBSCRIPTION_URI = (
  subscriptionUUID: string,
) => `/api/subscription/${subscriptionUUID}`;

const UPDATE_SUBSCRIPTION_TRIAL_URI = (
  subscriptionUUID: string,
) => `/api/subscription/${subscriptionUUID}/trial/update`;

const CREATE_SUBSCRIPTION_TRIAL_URI = (
  subscriptionUUID: string,
) => `/api/subscription/${subscriptionUUID}/trial/create`;

export class SubscriptionApiService {
  public static async getSubscriptionList(
    args: IGenericListQuery,
  ): Promise<ISubscriptionListResponseDto> {
    return await ApiService.get(GET_SUBSCRIPTION_LIST_URI, args);
  }

  public static async deleteSubscription(subscriptionUUID: string): Promise<void> {
    return await ApiService.delete(DELETE_SUBSCRIPTION_URI(subscriptionUUID));
  }

  public static async updateSubscriptionTrial(
    subscriptionUUID: string,
    subscriptionTrial: Partial<ISubscriptionTrial>,
  ): Promise<ISubscriptionTrial> {
    return await ApiService.post(
      UPDATE_SUBSCRIPTION_TRIAL_URI(subscriptionUUID),
      subscriptionTrial,
    );
  }

  public static async createSubscriptionTrial(
    subscriptionUUID: string,
  ): Promise<ISubscriptionTrial> {
    return await ApiService.post(
      CREATE_SUBSCRIPTION_TRIAL_URI(subscriptionUUID),
    );
  }
}
