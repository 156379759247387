export const subscriptionTrialHeaders = [
  {
    text: 'Duration (days)',
    value: 'subscriptionTrial.durationDays',
  },
  {
    text: 'Created at',
    value: 'subscriptionTrial.createdAt',
    width: '25%',
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];

export const observerHeaders = [
  {
    text: 'Email',
    value: 'observerUser.contact.email',
    width: '25%',
  },
  {
    text: 'Role',
    value: 'role',
    width: '25%',
  },
  {
    text: 'Mail notifications allowed ?',
    value: 'observerUser.contact.mailNotificationAllowed',
  },
  {
    text: 'Number of devices',
    value: 'observerUser.deviceIOSList.length',
  },
  {
    text: 'Created at',
    value: 'observerUser.createdAt',
  },
];

export const attendeeHeaders = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Gender',
    value: 'gender',
  },
  {
    text: 'Birth date',
    value: 'birthDate',
  },
  {
    text: 'Number of devices',
    value: 'nDevice',
  },
  {
    text: 'Rest period',
    value: 'restPeriod',
  },
  {
    text: 'Preset',
    value: 'preset',
  },
  {
    text: 'Mediation state',
    value: 'mediationState',
  },
  {
    text: 'Number of snapshots',
    value: 'nSnapshots',
  },
  {
    text: 'Created at',
    value: 'createdAt',
  },
];

export const headers = [
  {
    text: 'UUID',
    value: 'uuid',
    sortable: false,
  },
  {
    text: 'Referent observer user',
    value: 'subscriptionObserverUserRoleList[0].observerUser.contact.email',
    sortable: false,
  },
  {
    text: 'Validity',
    value: 'subscriptionTrialValidity',
    sortable: false,
  },
  {
    text: 'Created at',
    value: 'createdAt',
    sortable: false,
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
];
