export const adminAppParametersRuleListJson = {
  trialDurationDaysRules: [
    (v: string) => Boolean(v) || 'Trial duration is required',
    (v: string) => Number.isInteger(Number(v)) || 'The value must be an integer number',
  ],
  softModeDefaultPercentRules: [
    (v: string) => Boolean(v) || 'Soft mode default percent is required',
    (v: string) => Number.isInteger(Number(v)) || 'The value must be an integer number',
  ],
};
