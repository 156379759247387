
import Vue from 'vue';
import {ScreenTimeApiService} from '@/service/api/screenTime/screen.time.api.service';

export default Vue.extend({
  data: () => ({
    synchronized: false,
  }),
  methods: {
    async removeCompletedScreenTimeJobs() {
      await ScreenTimeApiService.removeCompletedScreenTimeJobs();
      this.synchronized = true;
    },

    closeSynchronizedAlert() {
      this.synchronized = false;
    },
  },
});
