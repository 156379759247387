
import Vue from 'vue';
import {IPaginationOptions} from '@/interface/pagination.options.interface';
import {deviceStore, EDeviceStoreAttribute} from '@/store/device.store';
import {footerProps} from '@/views/table.const';
import {formatDateTime} from '@/filters/format.date.time.filter';

export default Vue.extend({
  data: () => ({
    footerProps,
    options: {} as IPaginationOptions,
    search: '',
    isLoading: false,
    headers: [
      {
        text: 'UUID',
        value: 'uuid',
        sortable: false,
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Model',
        value: 'model',
        sortable: false,
      },
      {
        text: 'Language',
        value: 'language',
        sortable: false,
      },
      {
        text: 'Subscription UUID',
        value: 'subscriptionUUID',
        sortable: false,
      },
      {
        text: 'Observer user email',
        value: 'observerUserEmail',
        sortable: false,
      },
      {
        text: 'Created at',
        value: 'createdAt',
        sortable: false,
      },
    ],
  }),
  filters: {
    formatDateTime,
  },
  computed: {
    totalDevice() {
      return deviceStore.state.totalDevice;
    },
    deviceList() {
      return deviceStore.state[EDeviceStoreAttribute.DEVICE_LIST];
    },
  },
  watch: {
    async options() {
      await this.getDataFromApi();
    },
  },
  methods: {
    async getDataFromApi() {
      this.isLoading = true;
      await this.$store.dispatch(
        'getDeviceList',
        {
          pageNumber: this.options.page - 1 ?? 0,
          pageSize: this.options.itemsPerPage ?? 10,
          search: this.search,
        },
      );
      this.isLoading = false;
    },
  },
});
