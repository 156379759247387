
import Vue from 'vue';
import {isDef} from './service/static/control.service';
import AvatarMenu from './components/avatar-menu.vue';
import NavigationBarLeft from './template/navigation-bar-left.vue';
import {ECurrentUserStoreAttribute} from './store/user/current.user.store';

export default Vue.extend({
  name: 'App',
  components: {NavigationBarLeft, AvatarMenu},
  data: () => ({
    //
  }),
  methods: {},
  computed: {
    userIsLogged() {
      return isDef(this.$store.getters[ECurrentUserStoreAttribute.USER]);
    },
  },
});
