
import Vue from 'vue';
import {ObserverUserApiService} from '@/service/api/user/observerUser/observer.user.api.service';
import {SignInCodeApiService} from '@/service/api/user/signInCode/sign.in.code.api.service';

export default Vue.extend({
  data: () => ({
    ghostObserverUserDeleted: false,
    expiredSignInCodeDeleted: false,
  }),
  methods: {
    async deleteGhostObserverUsers() {
      this.ghostObserverUserDeleted = true;
      await ObserverUserApiService.deleteGhostObserverUser({olderThanDays: 60});
    },

    async deleteExpiredSignInCode() {
      this.expiredSignInCodeDeleted = true;
      await SignInCodeApiService.deleteExpiredSignInCode();
    },

    closeGhostObserverUserDeletedAlert() {
      this.ghostObserverUserDeleted = false;
    },

    closeExpiredSignInCodeDeletedAlert() {
      this.expiredSignInCodeDeleted = false;
    },
  },
});
