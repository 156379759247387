
import Vue from 'vue';
import {VForm} from '@/type/vform.type';
import draggable from 'vuedraggable';
import Sortable from 'sortablejs';
import {ERecommendedAppStoreAction} from '@/store/recommended.app.store';
import {IRecommendedApp} from '@/interface/recommended.app.interface';
import {EDevicePlatform} from '@/enum/device.enum';

export default Vue.extend({
  props: {
    show: Boolean,
    recommendedAppList: Array,
    devicePlatform: {
      type: () => EDevicePlatform
    },
  },
  data() {
    return {
      listToSort: [...this.recommendedAppList],
      valid: true,
      headers: [
        {
          text: 'Move',
          value: 'move',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Device type list',
          value: 'deviceTypeList',
          sortable: false,
        },
        {
          text: 'Country list',
          value: 'countryList',
          sortable: false,
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
        },
      ],
    };
  },
  components: {
    draggable,
  },
  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },
  },
  methods: {
    saveOrder(event) {
      const movedItem = this.listToSort.splice(event.oldIndex, 1)[0];
      this.listToSort.splice(event.newIndex, 0, movedItem);
    },
    close() {
      this.$emit('close');
    },
    async save() {
      if (this.form.validate()) {
        await this.$store.dispatch(
          // 'updateFullActiveRecommendedIOSAppList',
            ERecommendedAppStoreAction.UPDATE_FULL_ACTIVE_RECOMMENDED_APP_LIST,
          // {
          //   recommendedAppList: this.listToSort,
          {recommendedAppList: this.listToSort, devicePlatform: this.devicePlatform},
        );
        this.$emit('save');
      }
    },
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: (event) => {
            vnode.child.$emit('sorted', event);
          },
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
      },
    },
  },
});
