export const isUndef = (v: any): boolean => v === undefined || v === null;

export const isDef = (v: any): boolean => v !== undefined && v !== null && v;

export const isTrue = (v: any): boolean => v === true;

export const isFalse = (v: any): boolean => v === false;

export const isPrimitive = (value: any): boolean => (
  typeof value === 'string'
  || typeof value === 'number'
  // $flow-disable-line
  || typeof value === 'symbol'
  || typeof value === 'boolean'
);

export const isObject = (obj: any): boolean => obj !== null && typeof obj === 'object';

const rToString = Object.prototype.toString;

export const toRawType = (value: any): any => rToString.call(value).slice(8, -1);

export const isPlainObject = (obj: any): boolean => rToString.call(obj) === '[object Object]';

export const isRegExp = (v: any): boolean => rToString.call(v) === '[object RegExp]';

export const isValidArrayIndex = (val: any): boolean => {
  const n = parseFloat(String(val));
  // eslint-disable-next-line
  return n >= 0 && Math.floor(n) === n && isFinite(val);
};

export const isPromise = (val: any): boolean => (
  isDef(val)
  && typeof val.then === 'function'
  && typeof val.catch === 'function'
);

export const formatWeight = (v: string | number): string => {
  if (v) {
    console.log(v);
    let value = v.toString();
    const hasDecimal = value.indexOf(',') > -1;
    let g = value;
    let kg = value;
    let ton = '';
    let kton = '';
    let Mton = '';
    let Gton = '';
    let decimal = '';
    let vOutput = '';

    if (hasDecimal) {
      const [vG, vDecimal] = value.split(',');
      value = vG;
      g = vG;
      decimal = `,${vDecimal.substr(0, 2)}`;
    }

    const isKg = (value.length > 3 && value.length < 7);
    const isTon = (value.length > 6 && value.length < 10);
    const isKTon = (value.length > 9 && value.length < 13);
    const isMTon = (value.length > 12 && value.length < 16);
    const isGTon = (value.length > 15);

    if (!isKg && !isTon && !isKTon && !isMTon && !isGTon) {
      vOutput = `${g}${decimal}g`;
    } else if (isKg) {
      kg = value.substr(0, value.length - 3);
      g = value.substr(value.length - 3, 2);
      vOutput = `${kg},${g}kg`;
    } else if (isTon) {
      ton = value.substr(0, value.length - 6);
      kg = value.substr(value.length - 6, 2);
      vOutput = `${ton},${kg}t`;
    } else if (isKTon) {
      kton = value.substr(0, value.length - 9);
      ton = value.substr(value.length - 9, 2);
      vOutput = `${kton},${ton}kt`;
    } else if (isMTon) {
      Mton = value.substr(0, value.length - 12);
      kton = value.substr(value.length - 12, 2);
      vOutput = `${Mton},${kton}Mt`;
    } else if (isGTon) {
      Gton = value.substr(0, value.length - 15);
      Mton = value.substr(value.length - 15, 2);
      vOutput = `${Gton},${Mton}Gt`;
    } else {
      vOutput = '-';
    }

    return `${vOutput}`;
  }
  return '0';
};

export const formatBytes = (v: string | number): string => {
  if (v) {
    let value = v.toString();
    const hasDecimal = value.indexOf(',') > -1;
    let g = value;
    let kg = value;
    let ton = '';
    let kton = '';
    let decimal = '';
    let vOutput = '';

    if (hasDecimal) {
      const [vG, vDecimal] = value.split(',');
      value = vG;
      g = vG;
      decimal = `,${vDecimal.substr(0, 2)}`;
    }
    console.log(value.split(/(\s+)/));
    const isKg = (value.length > 3 && value.length < 7);
    const isTon = (value.length > 6 && value.length < 10);
    const isKTon = (value.length > 9);

    if (!isKg && !isTon && !isKTon) {
      vOutput = `${g}${decimal}bytes`;
    } else if (isKg) {
      kg = value.substr(0, value.length - 3);
      g = value.substr(value.length - 3, 2);
      vOutput = `${kg},${g}ko`;
    } else if (isTon) {
      ton = value.substr(0, value.length - 6);
      kg = value.substr(value.length - 6, 2);
      vOutput = `${ton},${kg}go`;
    } else if (isKTon) {
      kton = value.substr(0, value.length - 9);
      ton = value.substr(value.length - 9, 2);
      vOutput = `${kton},${ton}to`;
    } else {
      vOutput = '-';
    }

    return `${vOutput}`;
  }
  return '0';
};

export const isStringEitherUndefNullEmpty = (val: string): boolean => isUndef(val) || val === null || val === '';
