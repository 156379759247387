import {IGenericListQuery} from '@/service/api/query/generic.list.get.query';
import {
  IInternalUserListResponseDto,
} from '@/service/api/user/internalUser/dto/internal.user.list.response.dto';
import {ApiService} from '@/service/api/api.service';
import {IInternalUser} from '@/interface/internal.user.interface';
import {ICreateInternalUserDto} from '@/service/api/user/internalUser/dto/create.internal.user.dto';
import {EUserType} from '@/enum/user.enum';

const GET_INTERNAL_USER_LIST_URI = '/api/admin/internal-user';
const NEW_INTERNAL_USER_URI = '/api/admin/internal-user/new';
const UPDATE_INTERNAL_USER_URI = (
  internalUserUUID: string,
) => `/api/admin/internal-user/${internalUserUUID}`;
const DELETE_INTERNAL_USER_URI = (
  internalUserUUID: string,
) => `/api/admin/internal-user/${internalUserUUID}`;

export class InternalUserApiService {
  public static async getInternalUserList(
    args: IGenericListQuery,
  ): Promise<IInternalUserListResponseDto> {
    return await ApiService.get(GET_INTERNAL_USER_LIST_URI, args);
  }

  public static async newInternalUser(
    internalUser: ICreateInternalUserDto,
  ): Promise<IInternalUser> {
    const dataToSend = {
      ...internalUser,
      type: EUserType.INTERNAL_USER,
    };
    return await ApiService.post(NEW_INTERNAL_USER_URI, dataToSend);
  }

  public static async updateInternalUser(
    internalUserUUID: string,
    internalUserUpdateDto: Partial<IInternalUser>,
  ): Promise<IInternalUser> {
    const internalUser: IInternalUser = await ApiService.post(
      UPDATE_INTERNAL_USER_URI(internalUserUUID),
      internalUserUpdateDto,
    );
    return internalUser;
  }

  public static async deleteInternalUser(internalUserUUID:string): Promise<void> {
    await ApiService.delete(DELETE_INTERNAL_USER_URI(internalUserUUID));
  }
}
